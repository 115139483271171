import { notification } from '_helpers/notification'
import { getApiUrl } from '../_services/apiUrlProvider'

export const isPPNCheck = setIsPPN =>
  fetch(`${getApiUrl()}/api/ppn_hostname`, {
    method: 'GET',
  })
    .then(
      response => {
        if (!response.ok) {
          notification(
            'error',
            'T_FRONT_PREVIEW_ERROR_TEXT',
            'T_FRONT_PREVIEW_ERROR_TITLE'
          )

          return ''
        }

        return response.json()
      },
      () => {}
    )
    .then(data => {
      if (data?.hostname === window.location.hostname.replace('admin.', '')) {
        setIsPPN(true)
      } else {
        setIsPPN(false)
      }

      if (data?.currentHostnameTitle) {
        document.title = 'Panel - ' + data.currentHostnameTitle
      }
    })
