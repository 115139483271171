import React, { useMemo } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '../../../_schema/bipPageArticle'
import routes from '../routes'
import itemRoutes from '../routes'
import { LANGS_IRI } from '../../../_lib/langs'
import { EditHeaderWithCustomTitle } from '../../../components/EditHeaderWithCustomTitle'
import { operations } from './table/columns/operations'

export const ArticleVersionCollection = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        width: '65%',
      },
      {
        header: translate('T_GENERAL_CREATED_AT'),
        accessor: `createdAtHuman`,
        width: '15%',
      },
      {
        ...operations(`translations.${LANGS_IRI.PL}.title`, true, false, false),
        width: '10%',
      },
    ],
    []
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_BIP_PAGE_ARTICLE_NEW_BUTTON')}
        buttonPath={`${itemRoutes().articleNew.path}?pid=${match.params.id}`}
        hideButton={true}
      >
        <EditHeaderWithCustomTitle
          resource={resource}
          editPath={routes().articleEdit.path}
          editLinkTitle={'T_MODULE_BIP_PAGE_COLLECTION_EDIT_ARTICLE_LINK'}
          textBeforeTitle={`Wersje artykułu: ${
            resource.translations?.[
              LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
            ]?.title
          }`}
        />
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        //path={`paths.${itemSchema.endpoint}.get`}
        endpoint={`${schema.endpoint}`}
        definition={schema.resource.definition}
        storeCollectionId={`${schema.endpoint}?originalUuid=${match.params.id}`}
        defaultFilters={{
          originalUuid: match.params.id,
        }}
        parameters={[
          {
            name: 'originalUuid',
            in: 'query',
            type: 'string',
            value: false,
          },
        ]}
        columns={itemColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={itemRoutes().articleEdit.path}
        autoWidth={false}
        paginable={false}
      />
    </Paper>
  )
}
