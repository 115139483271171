import photoSchema from './photo'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/shop_categories',
  collectionEndpoint: '/api/shop_categories/select',
  clone_endpoint: '/api/shop_category/clone',
  resource: {
    definition: '#ShopCategory',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            type: 'tinymce',
            description: 'T_GENERAL_DESCRIPTION',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/shop_category_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
