import React, { useMemo } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { EditHeader } from 'components/EditHeader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { ResourceMediaAddMultipleDialog } from 'components/ResourceMediaAddMultipleDialog'
import { operations } from 'components/table/_columns/operations'
import { applyProperties } from '_helpers/applyProperties'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import { MainCell } from './table/cells/MainCell'
import schema from '_schema/packageGallery'
import routes from './routes'
import itemSchema from '_schema/packageGalleryItem'
import itemRoutes from 'pages/PackageGalleryItems/routes'
import { LANGS_IRI } from '../../_lib/langs'
import { makeStyles } from '@material-ui/core/styles'
import { getApiUrl } from '../../_services/apiUrlProvider'
import { authHeader } from '../../_helpers/authHeader'
import { notification } from '../../_helpers/notification'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  table: {
    minWidth: 400,
  },
}))

export const Show = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        filterName: 'translations.title',
        filterable: true,
        Cell: MainCell,
        width: '65%',
      },
      {
        ...operations(`translations.${LANGS_IRI.PL}.title`),
        width: '15%',
      },
    ],
    []
  )

  const itemMassProperties = useMemo(
    () =>
      applyProperties(
        {
          translations: itemSchema.resource.properties.translations,
          stat: itemSchema.resource.properties.stat,
        },
        definitions[itemSchema.resource.definition].properties,
        'missing'
      ),
    [definitions]
  )

  const [open, setOpen] = React.useState(false)
  const [isFetchingRelations, setIsFetchingRelations] = React.useState(false)
  const [relations, setRelations] = React.useState([])
  const classes = useStyles()

  const relationTypes = {
    'App\\Entity\\Matter': 'Załatw sprawę',
    'App\\Entity\\TourismNoticeArticle': 'Komunikat turystyczny',
    'App\\Entity\\Trail': 'Trasa',
    'App\\Entity\\Page': 'Struktura stron',
    'App\\Entity\\News': 'Aktualność',
    'App\\Entity\\Event': 'Wydarzenie',
    'App\\Entity\\Contest': 'Konkurs',
    'App\\Entity\\Volunteering': 'Wolontariat',
    'App\\Entity\\VolunteeringEvent': 'Wydarzenie wolontariatu',
    'App\\Entity\\Park': 'Park',
  }

  const handleClickOpen = () => {
    setOpen(true)
    if (relations.length === 0) {
      fetchRelations()
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const fetchRelations = () => {
    if (isFetchingRelations) {
      return
    }
    setIsFetchingRelations(true)
    fetch(
      `${getApiUrl()}/api/package_galleries_relations?uuid=${match.params.id}`,
      {
        method: 'GET',
        headers: {
          ...authHeader(),
        },
      }
    )
      .then(
        response => {
          if (!response.ok) {
            notification(
              'error',
              'T_FRONT_PREVIEW_ERROR_TEXT',
              'T_FRONT_PREVIEW_ERROR_TITLE'
            )
            setIsFetchingRelations(false)
            return []
          }

          return response.json()
        },
        () => {}
      )
      .then(data => {
        setIsFetchingRelations(false)
        setRelations(data)
      })
  }

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_BUTTON')}
        buttonPath={`${itemRoutes().new.path}?pid=${match.params.id}`}
        additionalButtons={
          <>
            <ResourceMediaAddMultipleDialog
              title={translate(
                'T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_MULTIPLE_BUTTON'
              )}
              parentIri={parentIri}
              fileEndpoint={
                itemSchema.resource.properties.media.endpointMultiple
              }
              itemEndpoint={itemSchema.endpoint}
              itemProperties={itemMassProperties}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Miejsca użycia
            </Button>
          </>
        }
      >
        <EditHeader resource={resource} editPath={routes().edit.path} />
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${itemSchema.endpoint}.get`}
        endpoint={itemSchema.endpoint}
        definition={itemSchema.resource.definition}
        storeCollectionId={`${itemSchema.endpoint}?iri[parent]=${parentIri}`}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            type: 'string',
            value: false,
          },
        ]}
        columns={itemColumns}
        customResourceSchema={itemSchema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={itemRoutes().edit.path}
        autoWidth={false}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Miejsca użycia</DialogTitle>
        <DialogContent dividers={true}>
          <div
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {isFetchingRelations ? (
              <div className={classes.root}>
                <CircularProgress />
              </div>
            ) : (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    {relations.length > 0 &&
                      relations.map(row => (
                        <TableRow key={row['@id']}>
                          <TableCell component="th" scope="row">
                            {row.title} [{relationTypes[row['class']]}]
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {!isFetchingRelations && relations.length === 0 && (
              <div className={classes.root}>Brak</div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}
