import React, { useMemo } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { translate } from '_helpers/translate'
import schema from '../../_schema/customer'
import routes from './routes'
import { operations } from '../../components/table/_columns/operations'
import { DatetimeCell } from '../../components/table/cells/DatetimeCell'
import { LoginStatusCell } from '../../components/table/cells/LoginStatusCell'

export const LoginLogCollection = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: translate('Email'),
        accessor: `parent.email`,
        width: '50%',
      },
      {
        header: translate('Adres ip'),
        accessor: `ip`,
        width: '15%',
      },
      {
        header: translate('Status'),
        accessor: `result`,
        filterable: true,
        sortable: true,
        sorterName: 'status',
        width: '10%',
        Cell: LoginStatusCell,
      },
      {
        header: translate('Data logowania'),
        accessor: `createdAt`,
        width: '20%',
        sorterName: 'createdAt',
        filterName: 'createdAt',
        filterable: true,
        sortable: true,
        Cell: DatetimeCell,
      },
      {
        ...operations(`createdAt`, false, false, false),
        width: '10%',
      },
    ],
    []
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_TRAIL_REVIEW_NEW_BUTTON')}
        buttonPath={`${routes().loginLogCollection.path}?pid=${
          match.params.id
        }`}
        hideButton={true}
      >
        {translate('Historia logowania')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.subresources.loginLog.endpoint}.get`}
        endpoint={schema.subresources.loginLog.endpoint}
        definition={schema.subresources.loginLog.definition}
        storeCollectionId={`${schema.subresources.loginLog.endpoint}?iri[parent]=${parentIri}`}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            type: 'string',
            value: false,
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
        ]}
        columns={itemColumns}
        customResourceSchema={schema.subresources.loginLog}
        defaultSorters={{ createdAt: 'desc' }}
        orderable={false}
        isRowLinkable={false}
        editPath={routes().loginLogEdit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
