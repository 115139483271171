const schema = {
  endpoint: '/api/guides',
  access: 'guide',
  resource: {
    definition: '#Guide',
    properties: {
      name: {
        description: 'T_GENERAL_GUIDE_NAME',
        validate: ['required'],
      },
      surname: {
        validate: ['required'],
      },
      email: {
        validate: ['required'],
      },
      phone: {},
      addressStreet: {},
      addressPostal: {},
      addressCity: {},
      isGuide: {},
      isInstructor: {},
      isTPNEmployee: {},
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
