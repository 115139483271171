const FIELD_TYPES = {
  INTEGER: 'integer',
  NUMBER: 'number',
  FLOAT: 'float',
  DECIMAL: 'decimal',
  BOOLEAN: 'boolean',
  STRING: 'string',
  TEXTAREA: 'textarea',
  TINYMCE: 'tinymce',
  CHOICE: 'choice',
  DATE: 'date',
  DATETIME: 'datetime',
  TIME: 'time',
  PASSWORD: 'password',
  PASSWORD_REPEAT: 'password_repeat',
  SLUG: 'slug',
  RESOURCE: 'resource',
  MULTIPLE_RESOURCE: 'multiple_resource',
  MAP: 'map',
  TRANSLATION: 'translation',
  FILE: 'file',
  IMAGE: 'image',
  YOUTUBE: 'youtube',
  AUTOCOMPLETE: 'autocomplete',
  COLLECTION: 'collection',
  EMBEDDED_COLLECTION: 'embedded_collection',
  JSON_DATA: 'json_data',
  HEADER: 'header',
}

export default FIELD_TYPES
