import { StringType } from './StringType'
import { IntegerType } from './IntegerType'
import { NumberType } from './NumberType'
import { TinymceType } from './TinymceType'
import { Tinymce3Type } from './Tinymce3Type'
import { PasswordRepeatType } from './PasswordRepeatType'
import { BooleanType } from './BooleanType'
import { ResourceType } from './ResourceType'
import { DoubleResourceType } from './DoubleResourceType'
import { ResourceWithIconType } from './ResourceWithIconType'
import { MultipleResourceType } from './MultipleResourceType'
import { DateType } from './DateType'
import { DateTimeType } from './DateTimeType'
import { MapType } from './MapType'
import { StreetMapType } from './StreetMapType'
import { TranslationType } from './translation/TranslationType'
import { FileType } from './file/FileType'
import { ImageType } from './ImageType'
import { YoutubeType } from './YoutubeType'
import { ChoiceType } from './ChoiceType'
import { MediaType, MediaImageType, MediaYoutubeType } from './media'
import { CollectionType } from './CollectionType'
import { CustomBlockMultiSection } from './CustomBlockMultiSection'
import { EmbeddedCollectionType } from './EmbeddedCollectionType'
import { AlertType } from './AlertType'
import { UserLinkType } from './UserLinkType'
import { ReturnMessageType } from './ReturnMessageType'
import { AutoComplete } from './AutoComplete'
import { HeaderType } from './HeaderType'
import { ShopOrderVariantType } from './ShopOrderVariantType'
import { ShopOrderShippingType } from './ShopOrderShippingType'
import { ElektronicznyNadawcaResourceType } from './ElektornicznyNadawcaResourceType'
import { SendOrderFvType } from './SendOrderFvType'
import { SlugType } from './SlugType'
import { ArticlePreviewUuidType } from './ArticlePreviewUuidType'

export const fields = {
  string: StringType,
  integer: IntegerType,
  number: NumberType,
  decimal: NumberType,
  textarea: StringType,
  tinymce: TinymceType,
  tinymce3: Tinymce3Type,
  password: StringType,
  passwordRepeat: PasswordRepeatType,
  boolean: BooleanType,
  resource: ResourceType,
  resourceWithIcon: ResourceWithIconType,
  doubleResource: DoubleResourceType,
  multipleResource: MultipleResourceType,
  date: DateType,
  datetime: DateTimeType,
  dateTime: DateTimeType,
  map: MapType,
  streetmap: StreetMapType,
  translation: TranslationType,
  file: FileType,
  image: ImageType,
  youtube: YoutubeType,
  choice: ChoiceType,
  'media[type]': MediaType,
  'media[image]': MediaImageType,
  'media[youtube]': MediaYoutubeType,
  collection: CollectionType,
  embeddedCollection: EmbeddedCollectionType,
  customBlockMultiSection: CustomBlockMultiSection,
  alert: AlertType,
  userLink: UserLinkType,
  returnMessage: ReturnMessageType,
  autoComplete: AutoComplete,
  header: HeaderType,
  shopOrderVariant: ShopOrderVariantType,
  shopOrderShipping: ShopOrderShippingType,
  elektornicznyNadawcaResourceType: ElektronicznyNadawcaResourceType,
  shopOrderSendFv: SendOrderFvType,
  slug: SlugType,
  articlePreviewUuid: ArticlePreviewUuidType,
}
