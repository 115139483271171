import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'

const useStyles = makeStyles({
  input: {
    width: '100%',
    paddingLeft: 6,
    paddingRight: 4,
    '& input': {
      paddingTop: 13,
    },
  },
})

export const StringFilter = ({
  name,
  title,
  value,
  disabled,
  handleEnter,
  setFilter,
  classes = {},
}) => {
  const handleChange = e => {
    const value = e.target.value
    setFilter(name, value || null)
  }

  const handleKeyDown = e => {
    switch (e.key) {
      case 'Enter':
        handleEnter && handleEnter()
        break
      default:
        break
    }
  }

  const defaultClasses = useStyles()

  return (
    <TextField
      name={`filter-text-${name}`}
      value={value || ''}
      helperText={title}
      className={clsx(defaultClasses.input, classes.input)}
      disabled={disabled}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      size="small"
      variant="filled"
    />
  )
}

StringFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  handleEnter: PropTypes.func,
  setFilter: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
}
