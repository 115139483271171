import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/trailReview'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_AUTHOR'),
        accessor: `name`,
        filterable: true,
        sortable: true,
        width: '20%',
      },
      {
        header: translate('T_MODULE_TRAIL_TITLE'),
        accessor: `parentTitle`,
        filterable: true,
        sortable: true,
        width: '20%',
      },
      {
        header: translate('T_GENERAL_GRADE'),
        accessor: `gradeHelper`,
        filterable: true,
        sortable: true,
        width: '5%',
      },
      {
        header: translate('T_GENERAL_CREATED_AT'),
        accessor: `createdAtHuman`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        ...operations(`name`, true, true, true, true, false),
        width: '10%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_TRAIL_REVIEW_NEW_BUTTON')}
        buttonPath={routes().new.path}
        hideButton={true}
      >
        {translate('T_MODULE_TRAIL_REVIEW')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={false}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
