import React, { useEffect, useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/packageFile'
import routes from './routes'
import { currentHostnameIri } from '../../_helpers/currentHostnameIri'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        accessor: 'title',
        filterable: true,
        sortable: true,
        width: '65%',
      },
      { ...operations('title', true, true, true, true, false), width: '15%' },
    ],
    []
  )

  const [hostnameIri, setHostnameIri] = useState(false)
  const [isHostnameLoading, setIsHostnameLoading] = useState(false)

  useEffect(() => {
    if (!isHostnameLoading && hostnameIri === false) {
      setIsHostnameLoading(true)
      currentHostnameIri(setHostnameIri)
    }

    if (hostnameIri !== false) {
      setIsHostnameLoading(false)
    }
  }, [hostnameIri, setHostnameIri, isHostnameLoading, setIsHostnameLoading])

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_PACKAGE_FILES_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >
        {translate('T_MODULE_PACKAGE_FILES')}
      </CollectionHeader>
      {!isHostnameLoading && (
        <SchemableCollection
          component={CollectionTable}
          path={`paths.${schema.endpoint}.get`}
          endpoint={schema.endpoint}
          definition={schema.resource.definition}
          columns={columns}
          customResourceSchema={schema.resource}
          defaultSorters={{ ord: 'desc' }}
          defaultFilters={{ 'iri[hostname]': hostnameIri }}
          parameters={[
            {
              name: 'iri[hostname]',
              in: 'query',
              required: false,
              type: 'boolean',
            },
          ]}
          orderable={true}
          selectable={[publish.on(), publish.off()]}
          isRowLinkable={true}
          editPath={routes().edit.path}
          autoWidth={false}
        />
      )}
    </Paper>
  )
}
