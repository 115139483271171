import React, { useMemo } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '../../../_schema/tourismNoticeArticle'
import routes from '../routes'
import itemRoutes from '../routes'
import { EditHeaderWithCustomTitle } from '../../../components/EditHeaderWithCustomTitle'
import { operations } from './table/columns/operations'
import { DatetimeCell } from '../../../components/table/cells/DatetimeCell'

export const ArticleVersionCollection = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(
    () => schema.getEndpoint.replace(':uuid', match.params.id),
    [match.params.id]
  )

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: translate('Opublikowany od'),
        accessor: `createdAt`,
        width: '15%',
        Cell: DatetimeCell,
      },
      {
        header: translate('Opublikowany do'),
        accessor: `unpublishedAt`,
        width: '15%',
        Cell: DatetimeCell,
      },
      {
        header: translate('Utworzył'),
        accessor: `creatorName`,
        width: '15%',
      },
      {
        ...operations(`createdAt`, true, false, false),
        width: '10%',
      },
    ],
    []
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_BIP_PAGE_ARTICLE_NEW_BUTTON')}
        buttonPath={''}
        hideButton={true}
      >
        <EditHeaderWithCustomTitle
          resource={resource}
          editPath={routes().edit.path}
          editLinkTitle={'T_MODULE_BIP_PAGE_COLLECTION_EDIT_ARTICLE_LINK'}
          textBeforeTitle={`Historia komunikatu turystycznego`}
        />
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        //path={`paths.${itemSchema.endpoint}.get`}
        endpoint={`${schema.getCollectionEndpoint}`}
        definition={schema.resource.definition}
        storeCollectionId={`${schema.getCollectionEndpoint}?iri[originalEntity]=${parentIri}`}
        defaultFilters={{
          'iri[originalEntity]': parentIri,
        }}
        parameters={[
          {
            name: 'iri[originalEntity]',
            in: 'query',
            type: 'string',
            value: false,
          },
        ]}
        /*
        defaultFilters={{
          originalUuid: match.params.id,
        }}
        parameters={[
          {
            name: 'originalUuid',
            in: 'query',
            type: 'string',
            value: false,
          },
        ]}*/
        columns={itemColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={false}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={itemRoutes().versionEdit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
