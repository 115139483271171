import React, { useEffect, useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/accommodation'
import routes from './routes'
import { isPPNCheck } from '../../_helpers/isPPNCheck'

export const AccommodationCollection = ({ ...rest }) => {
  const { match } = rest
  const [isPPN, setIsPPN] = useState(false)
  const [isPPNLoading, setIsPPNLoading] = useState(false)

  useEffect(() => {
    if (!isPPNLoading) {
      setIsPPNLoading(true)
      isPPNCheck(setIsPPN)
    }
  }, [isPPN, setIsPPN, isPPNLoading, setIsPPNLoading])

  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `nativeTitle`,
        filterable: true,
        sortable: true,
        width: '70%',
      },

      {
        ...operations(
          `nativeTitle`,
          true,
          true,
          true,
          true,
          false,
          schema.clone_endpoint
        ),
        width: '10%',
      },
    ],
    []
  )

  const defaultFilters = {
    'user.uuid': match.params.pid,
  }

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_ACCOMM_NEW_BUTTON')}
        buttonPath={routes().accommodationNew.path.replace(
          ':pid',
          match.params.pid
        )}
      >
        {translate('Kwatery/wizytówki użytkownika')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        storeCollectionId={`${schema.endpoint}${match.params.pid}`}
        defaultFilters={defaultFilters}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'nativeTitle',
            in: 'query',
            type: 'string',
          },
          {
            name: 'user.uuid',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[ord]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[nativeTitle]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={!isPPN}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().accommodationEdit.path.replace(
          ':pid',
          match.params.pid
        )}
        autoWidth={false}
      />
    </Paper>
  )
}
