import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { translate } from '_helpers/translate'
import { prop } from '../../../../_helpers/prop'

export const ModuleCell = ({ resource, routes = [] }) => {
  const schema = useSelector(state => state.schema)

  let description =
    schema.definitions[`#${resource.resourceClass}`]?.['hydra:description']

  const entityClass = prop(resource, 'parentClass')
    ? prop(resource, 'parentClass')
    : prop(resource, 'resourceClass')

  routes.forEach(route => {
    if (route?.registryEntryDefinition === `#${entityClass}`) {
      description = route.title
      console.log(route.title, route)
    }
  })

  if (!description) {
    description = entityClass
  }

  return description ? <div>{translate(description)}</div> : null
}

ModuleCell.propTypes = {
  resource: PropTypes.shape({
    requestMethod: PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string,
  routes: PropTypes.array,
}
