import {
  text,
  textWithPhoto,
  textWithPhotoFloat,
  textWithPhotoZoom,
  textWithVideo,
  //textWithLink,
  gallery,
  files,
  //faq,
  //logotypes,
  anchors,
  alert,
  banner1,
  banner2,
  links,
  list,
  newsletter,
  product,
  trails,
  column,
  crew,
  miginfo,
  formsetBlock,
  tourismInformationNotices,
  trailConditions,
  embed,
  pricelist,
  inlinelogo,
  logotypesWithContent,
  contact2us,
  stops,
  heroBanner,
  shopCarousel,
  shopBenefits,
  shopBanner1,
  shopBanner2,
  shopPhotoVideo,
  formsetContest,
  hydrochart,
  a11string,
  a11text,
  newsCategories,
} from '_schema/_blocks'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockAnchorEndpoint,
  blockFileEndpoint,
  blockLinkEndpoint,
  blockLinkThumbEndpoint,
  blockListItemEndpoint,
  blockListItemThumbEndpoint,
  blockPricelistItemEndpoint,
  blockHeroBannerEndpoint,
  blockHeroBannerThumbEndpoint,
  definitionRefblockListItemEndpoint,
  definitionRefblockLinkEndpoint
) => ({
  types: [
    text(),
    textWithPhoto(photoEndpoint, blockThumbEndpoint),
    textWithPhotoFloat(photoEndpoint, blockThumbEndpoint),
    textWithPhotoZoom(photoEndpoint, blockThumbEndpoint),
    textWithVideo(photoEndpoint, blockThumbEndpoint),
    //textWithLink(),
    gallery(),
    files(),
    //faq(),
    //logotypes(),
    anchors(blockAnchorEndpoint),
    alert(),
    banner1(photoEndpoint, blockThumbEndpoint),
    banner2(photoEndpoint, blockThumbEndpoint),
    links(photoEndpoint, blockLinkEndpoint, blockLinkThumbEndpoint),
    list(photoEndpoint, blockListItemEndpoint, blockListItemThumbEndpoint),
    column(blockListItemEndpoint),
    newsletter(blockListItemEndpoint),
    product(photoEndpoint, blockThumbEndpoint),
    trails(photoEndpoint, blockThumbEndpoint, blockFileEndpoint),
    inlinelogo(
      photoEndpoint,
      blockListItemEndpoint,
      blockListItemThumbEndpoint,
      definitionRefblockListItemEndpoint
    ),
    logotypesWithContent(
      photoEndpoint,
      blockListItemEndpoint,
      blockListItemThumbEndpoint,
      definitionRefblockListItemEndpoint
    ),
    crew(),
    formsetBlock(),
    formsetContest(
      photoEndpoint,
      blockThumbEndpoint,
      'Blok z formularzem (baner) '
    ),
    miginfo(),
    tourismInformationNotices(),
    trailConditions(),
    stops(blockLinkEndpoint, definitionRefblockLinkEndpoint),
    contact2us(),
    embed(),
    pricelist(blockPricelistItemEndpoint),
    heroBanner(
      photoEndpoint,
      blockHeroBannerEndpoint,
      blockHeroBannerThumbEndpoint
    ),
    shopCarousel(photoEndpoint, blockThumbEndpoint),
    shopBenefits(),
    shopBanner1(photoEndpoint, blockThumbEndpoint),
    shopBanner2(photoEndpoint, blockThumbEndpoint),
    shopPhotoVideo(photoEndpoint),
    hydrochart(),
    a11string(),
    a11text(),
    newsCategories(),
  ],
})

export default schema
