import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import schema from '_schema/lessonSubmission'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'
import { DateCell } from '../../components/table/cells/DateCell'
import { DatetimeCell } from '../../components/table/cells/DatetimeCell'
import { useSelector } from 'react-redux'

export const Collection = () => {
  const profile = useSelector(state => state.profile)
  const [reloadCollection, setReloadCollection] = useState(true)

  const columns = useMemo(
    () => [
      {
        header: translate('Lekcja'),
        accessor: `lesson.translations.${LANGS_IRI.PL}.title`,
        filterable: true,
        filterName: 'lesson.translations.title',
        sortable: true,
        sorterName: 'lesson.translations.title',
        width: '15%',
      },
      {
        header: translate('Edukator'),
        accessor: `host.fullName`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        header: translate('Data'),
        accessor: 'schedule.date',
        filterable: true,
        sortable: true,
        Cell: DateCell,
        width: '5%',
      },
      {
        header: translate('Nauczyciel'),
        accessor: `contactName`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        header: translate('T_GENERAL_EMAIL'),
        accessor: `contactEmail`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        header: translate('T_GENERAL_PHONE_NUMBER'),
        accessor: `contactPhone`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        header: translate('Szkoła'),
        accessor: `school`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        header: translate('Klasa'),
        accessor: `class.trans`,
        translateAccessor: true,
        transTitle: true,
        filterable: true,
        sortable: true,
        sorterName: 'class',
        width: '15%',
      },
      {
        header: translate('Gmina'),
        accessor: `commune.translations.${LANGS_IRI.PL}.title`,
        filterable: true,
        filterName: 'commune.translations.title',
        sortable: true,
        sorterName: 'commune.translations.title',
        width: '15%',
      },
      {
        header: translate('Liczba osób'),
        accessor: `numberOfPeople`,
        filterable: true,
        sortable: true,
        width: '7%',
      },
      {
        header: translate('Czas'),
        accessor: `time`,
        filterable: true,
        sortable: true,
        width: '7%',
      },
      {
        header: translate('Data utworzenia'),
        accessor: `createdAt`,
        filterable: true,
        sortable: true,
        width: '7%',
        Cell: DatetimeCell,
      },
      {
        ...operations(
          `translations.${LANGS_IRI.PL}.title`,
          false,
          true,
          false,
          true,
          false
        ),
        width: '10%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_LESSON_SUBMISSION_NEW_BUTTON')}
        buttonPath={routes().new.path}
        hideButton={true}
      >
        {translate('T_MODULE_LESSON_MY_SUBMISSION')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        defaultFilters={{
          'host.email': profile.email,
        }}
        parameters={[
          {
            name: 'host.email',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'host.fullName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'lesson.translations.title',
            in: 'query',
            type: 'string',
          },
          {
            name: 'schedule.date',
            in: 'query',
            type: 'string',
          },
          {
            name: 'contactName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'contactEmail',
            in: 'query',
            type: 'string',
          },
          {
            name: 'contactPhone',
            in: 'query',
            type: 'string',
          },
          {
            name: 'school',
            in: 'query',
            type: 'string',
          },
          {
            name: 'commune.translations.title',
            in: 'query',
            type: 'string',
          },
          {
            name: 'createdAt',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[host.email]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[host.fullName]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[lesson.translations.title]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[schedule.date]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[contactName]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[contactEmail]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[contactPhone]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[school]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[class]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[commune.translations.title]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[numberOfPeople]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={false}
        isRowLinkable={false}
        editPath={routes().edit.path}
        autoWidth={false}
        reloadCollection={reloadCollection}
        setReloadCollection={setReloadCollection}
      />
    </Paper>
  )
}
