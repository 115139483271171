import mediaSchema from './media'
import photoSchema from "./photo";
import FIELD_TYPES from "../components/form/_helpers/fieldTypes";
const schema = {
  endpoint: '/api/config',
  access: 'config',
  resource: {
    definition: '#Config',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            description: 'T_CONFIG_PAGE_TITLE',
          },
          contactAddress: {
            description: 'T_CONFIG_CONTACT_ADDRESS',
            type: 'tinymce',
          },
          bipEntityName: {
            type: 'string',
            description: 'T_CONFIG_BIP_ENTITY_NAME',
          },
          footerAddress: {
            type: 'textarea',
          },
          footerEmail: {},
          footerPhone: {
            type: 'textarea',
          },
          // shopOrderAddress: {
          //   type: 'textarea'
          // },
          // shopFooterEmail:{
          //   type: 'string',
          // },
          // shopFooterPhone:{
          //   type: 'string',
          // },
          footerAccessibility: {
            type: 'textarea',
          },

          miginfoTitle: {
            hint: 'Domyślnie trans T_LANGUAGE_BLOCK_TITLE',
          },
          miginfoText: {
            type: 'textarea',
            hint: 'Domyślnie trans T_LANGUAGE_BLOCK_TEXT',
          },
          miginfoLink: {
            hint:
              'Domyślnie https://tlumacz.migam.org/tatrzański-park-narodowy-7xGtiTTF0XOaf5g6oaWX',
          },
          ticketmobileLink: {
            label: '[BILETY] Link',
            description: '[BILETY] Link',
            hint: 'link do biletów na belce dolnej w widoku mobilnym',
          },
        },
      },
      // file1: {
      //   type: 'file',
      //   description: '[Mapa] Plik z geojsonem szlaki piesze',
      //   label: '[Mapa] Plik z geojsonem szlaki piesze',
      //   endpoint: mediaSchema.endpoint,
      //   // validate: ['maxSize(20971520)'],
      //   // accept: 'text/xml',
      // },
      file1Link: {
        type: 'string',
        description: '[Mapa] Plik z geojsonem trasy piesze',
        label: '[Mapa] Plik z geojsonem trasy piesze',
      },
      file2: {
        type: 'file',
        description: '[Mapa] Plik z xml punkty',
        label: '[Mapa] Plik z xml punkty',
        endpoint: `${mediaSchema.endpoint}/xml2json`,
        // validate: ['maxSize(20971520)'],
        accept: 'text/xml',
        restrictManagerMimeType: 'text/xml',
      },
      file3: {
        type: 'file',
        description: '[Mapa] Plik z xml inne trasy',
        label: '[Mapa] Plik z xml inne trasy',
        endpoint: `${mediaSchema.endpoint}/xml2json`,
        // validate: ['maxSize(20971520)'],
        accept: 'text/xml',
        restrictManagerMimeType: 'text/xml',
      },

      freshApiKey: {
        type: 'string',
        label: '[FRESHMAIL] Key',
      },
      freshApiSecret: {
        type: 'string',
        label: '[FRESHMAIL] Secret',
      },
      freshApiList: {
        type: 'string',
        label: '[FRESHMAIL] Id',
        hint: 'id listy mailingowej na którą mają trafiać subskybenci',
      },

      miginfoOff: {},
      emailFrom: {
        description: 'T_CONFIG_EMAIL_SEND_ADDRESS',
      },

      googleMapApiKey: {
        description: 'T_CONFIG_GOOGLE_MAP_API_KEY',
      },
      additionalHeadCode: {
        type: 'textarea',
      },
      additionalBodyCode: {
        type: 'textarea',
      },

      // shopFreeShippingThresholdFloat: {
      //   type: 'number',
      //   description: 'T_SHOP_CONFIG_FREE_SHIPPING_THRESHOLD',
      //   validate: ['money'],
      // },
      // elektronicznyNadawcaLogin: {
      //   type: 'string',
      //   description: 'T_SHOP_CONFIG_ELEKTRONICZNY_NADAWCA_LOGIN',
      // },
      // elektronicznyNadawcaPassword: {
      //   type: 'password',
      //   description: 'T_SHOP_CONFIG_ELEKTRONICZNY_NADAWCA_PASSWORD',
      // },

      airlyKey: {
        type: 'string',
        description: 'Klucz Airly',
      },
      airlyInstalationId: {
        type: 'string',
        description: 'ID instalacji Airly',
      },
      airlyHomeLink: {
        type: 'string',
        description: 'Link do Airly',
      },
      seoMedia: {
        type: 'image',
        description: 'T_GENERAL_SEO_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: false,
      },
    },
  },
  subresources: {
    airlyEntries: {
      endpoint: '/api/config_airlies',
      definition: '#ConfigAirly',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              type: FIELD_TYPES.STRING,
              description: 'T_GENERAL_TITLE',
            },
          },
        },
        installationId: {
          type: FIELD_TYPES.STRING,
          description: 'ID instalacji',
        },
      },
    },
  },
}

export default schema
