import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from './components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { elektronicznyNadawcaSubscriptionXls } from '../ShopOrder/table/_mass'
import schema from '_schema/shopSubscription'
import routes from './routes'
import { DatetimeCell } from '../../components/table/cells/DatetimeCell'
import { ResourceFilter } from '../../components/table/filters/ResourceFilter'
import { StringFilter } from '../../components/table/filters/StringFilter'
import { PriceCell } from '../../components/table/cells/PriceCell'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_FIRST_NAME'),
        accessor: `firstName`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        header: translate('T_GENERAL_SURNAME'),
        accessor: `lastName`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        header: translate('T_GENERAL_EMAIL'),
        accessor: `email`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        header: translate('Numer zamówienia'),
        accessor: `shopOrder.orderId`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        header: translate('T_SHOP_MODULE_SUBSCRIPTION_STATUS'),
        accessor: `status.trans`,
        translateAccessor: true,
        transTitle: true,
        width: '10%',
      },
      {
        header: translate('T_SHOP_MODULE_SUBSCRIPTION_FROM'),
        accessor: `subscriptionFrom`,
        sortable: true,
        width: '5%',
      },
      {
        header: translate('T_SHOP_MODULE_SUBSCRIPTION_TO'),
        accessor: `subscriptionTo`,
        sortable: true,
        width: '5%',
      },
      {
        header: translate('T_SHOP_MODULE_SUBSCRIPTION_TOTAL'),
        accessor: `importTotal`,
        filterable: true,
        sortable: true,
        width: '5%',
      },
      {
        header: translate('Cena'),
        accessor: `importSubscriptionPrice`,
        width: '5%',
        Cell: PriceCell,
      },
      {
        header: translate('T_SHOP_MODULE_SUBSCRIPTION_SENDER'),
        accessor: `shippingMethod`,
        filterable: true,
        translateAccessor: true,
        transTitle: true,
        width: '10%',
      },
      {
        header: translate('T_GENERAL_CREATED_AT'),
        accessor: 'createdAt',
        filterable: true,
        sortable: true,
        Cell: DatetimeCell,
        width: '15%',
      },
      {
        ...operations(`email`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_SHOP_SUBSCRIPTION_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >
        {translate('T_MODULE_SHOP_SUBSCRIPTION')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        parameters={[
          {
            name: 'email',
            in: 'query',
            type: 'string',
          },
          {
            name: 'firstName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'lastName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'createdAt',
            in: 'query',
            type: 'string',
          },
          {
            name: 'shippingMethod',
            in: 'query',
            type: 'string',
          },
          {
            name: 'iri[status]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'subscriptionFrom[gte]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'subscriptionTo[lte]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'subscriptionFrom',
            in: 'query',
            type: 'string',
          },
          {
            name: 'subscriptionTo',
            in: 'query',
            type: 'string',
          },
          {
            name: 'json_contains_numbers',
            in: 'query',
            type: 'string',
          },
          {
            name: 'importSubscriptionPrice[gte]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'importSubscriptionPrice[lte]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'shopOrder.orderId',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[email]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[subscriptionFrom]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[subscriptionTo]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        customFilters={[
          {
            name: 'subscriptionFrom[gte]',
            title: 'Numer od (większy lub równy)',
            Filter: StringFilter,
          },
          {
            name: 'subscriptionTo[lte]',
            title: 'Numer do (mniejszy lub równy)',
            Filter: StringFilter,
          },
          {
            name: 'subscriptionFrom',
            title: 'Numer od',
            Filter: StringFilter,
          },
          {
            name: 'subscriptionTo',
            title: 'Numer do',
            Filter: StringFilter,
          },
          {
            name: 'json_contains_numbers',
            title: 'Zawiera numer (pomiędzy od-do)',
            Filter: StringFilter,
          },
          {
            name: 'importSubscriptionPrice[gte]',
            title: 'Cena od',
            Filter: StringFilter,
          },
          {
            name: 'importSubscriptionPrice[lte]',
            title: 'Cena do',
            Filter: StringFilter,
          },
          {
            name: 'iri[status]',
            title: 'Status',
            Filter: ResourceFilter,
            endpoint: '/api/shop_subscription_statuses?pagination=false',
            titleAccessor: 'trans',
          },
        ]}
        orderable={false}
        selectable={[elektronicznyNadawcaSubscriptionXls.generateXls()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        numerable={false}
      />
    </Paper>
  )
}
