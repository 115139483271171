const categoryChoices = {
  EKONOMICZNA: 'Ekonomiczna',
  PRIORYTETOWA: 'Priorytetowa',
}

const formatChoices = {
  S: 'S',
  M: 'M',
  L: 'L',
}

const gabarytChoices = {
  GABARYT_A: 'Gabaryt A',
  GABARYT_B: 'Gabaryt B',
}

const pocztexFormatChoices = {
  S: 'S',
  M: 'M',
  L: 'L',
  XL: 'XL',
  '2XL': '2XL',
}

const pocztexWeight = {
  5: 'do 5 kg',
  12: 'do 12 kg',
  20: 'do 20 kg',
}

/*
const cashOnDeliveryType = {
  //PRZEKAZ: 'Przekaz pocztowy',
  RACHUNEK_BANKOWY: 'Rachunek bankowy',
}*/

/*
const pocztexContent = {
  I: 'Inne',
  O: 'Owady',
  PG: 'Płyny lub gazy',
  SZ: 'Przedmioty łatwo tłukące się i szkło',
  LK: 'Rzeczy łamliwe i kruche',
  ZP: 'Żywe ptaki',
  ZR: 'Żywe rośliny',
}*/

const pocztexContent = {
  INNA: 'Inne',
  OWADY: 'Owady',
  PLYNY_LUB_GAZY: 'Płyny lub gazy',
  PRZEDMIOTY_LATWO_TLUKACE_SIE_I_SZKLO: 'Przedmioty łatwo tłukące się i szkło',
  RZECZY_LAMLIWE_I_KRUCHE: 'Rzeczy łamliwe i kruche',
  ZYWE_PTAKI: 'Żywe ptaki',
  ZYWE_ROSLINY: 'Żywe rośliny',
}

const sendLocation = {
  KURIER: 'Kurier',
  POCZTA: 'Placówka Pocztowa',
}

const schema = {
  endpoint: '/api/shop_elektroniczny_nadawca_add_shipment',
  endpointPdf: '/api/shop_elektroniczny_nadawca_print_label',
  endpointMultiPdf: '/api/shop_elektroniczny_nadawca_print_bufor_label',
  resource: {
    properties: {
      type: {
        type: 'elektornicznyNadawcaResourceType',
        endpoint: `/api/elektroniczny_nadawca_package_types?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        label: 'Typ przesyłki',
        validate: ['required'],
      },
    },
  },
  PRZESYLKA_POLECONA_KRAJOWA: {
    bufor: {
      type: 'resource',
      endpoint: `/api/shop_elektroniczny_nadawca_bufors?pagination=false&exists[finishedDate]=false`,
      titleAccessor: `selectTitle`,
      label: 'Pakiet',
      validate: ['required'],
    },
    category: {
      type: 'choice',
      choices: categoryChoices,
      label: 'Kategoria',
      validate: ['required'],
    },
    format: {
      type: 'choice',
      choices: formatChoices,
      label: 'Format',
      validate: ['required'],
      initialValue: 'S',
    },
    weight: {
      type: 'number',
      label: 'Masa[g]',
      validate: ['required'],
    },
  },
  PACZKA_POCZTOWA: {
    bufor: {
      type: 'resource',
      endpoint: `/api/shop_elektroniczny_nadawca_bufors?pagination=false&exists[finishedDate]=false`,
      titleAccessor: `selectTitle`,
      label: 'Pakiet',
      validate: ['required'],
    },
    category: {
      type: 'choice',
      choices: categoryChoices,
      label: 'Kategoria',
      validate: ['required'],
    },
    gabaryt: {
      type: 'choice',
      choices: gabarytChoices,
      label: 'Gabaryt',
      validate: ['required'],
    },
    weight: {
      type: 'number',
      label: 'Masa[g]',
      validate: ['required'],
    },
  },
  POCZTEX20: {
    bufor: {
      type: 'resource',
      endpoint: `/api/shop_elektroniczny_nadawca_bufors?pagination=false&exists[finishedDate]=false`,
      titleAccessor: `selectTitle`,
      label: 'Pakiet',
      validate: ['required'],
    },
    format: {
      type: 'choice',
      choices: pocztexFormatChoices,
      label: 'Format',
      validate: ['required'],
      initialValue: 'S',
    },
    masa: {
      type: 'choice',
      choices: pocztexWeight,
      label: 'Masa [kg]',
      validate: ['required'],
      initialValue: 5,
    },
    pocztexSendLocation: {
      type: 'choice',
      choices: sendLocation,
      label: 'Miejsce nadania',
      validate: ['required'],
      initialValue: 'POCZTA',
    },
    pocztexContent: {
      type: 'choice',
      choices: pocztexContent,
      label: 'Zawartość przesyłki',
      validate: ['required'],
      initialValue: 'INNA',
    },
    pocztexContentOther: {
      type: 'string',
      label: 'Zawartość przesyłki (inna)',
      validate: ['required'],
      fullWidth: true,
      initialValue: 'zamówienie TPN',
    },
    insurance: {
      type: 'number',
      label: 'Ubezpieczenie',
      hint: 'Kwota w zł',
      initialValue: 1000,
    },
    /*
    cashOnDeliveryType: {
      type: 'choice',
      choices: cashOnDeliveryType,
      label: 'Sposób pobrania',
      hint: 'TYLKO DLA PRZESYŁKI POBRANIOWEJ',
    },*/
  },
  POCZTEX_PICKUP_POINT: {
    bufor: {
      type: 'resource',
      endpoint: `/api/shop_elektroniczny_nadawca_bufors?pagination=false&exists[finishedDate]=false`,
      titleAccessor: `selectTitle`,
      label: 'Pakiet',
      validate: ['required'],
    },
    format: {
      type: 'choice',
      choices: pocztexFormatChoices,
      label: 'Format',
      validate: ['required'],
      initialValue: 'S',
    },
    masa: {
      type: 'choice',
      choices: pocztexWeight,
      label: 'Masa [kg]',
      validate: ['required'],
      initialValue: 5,
    },
    pocztexSendLocation: {
      type: 'choice',
      choices: sendLocation,
      label: 'Miejsce nadania',
      validate: ['required'],
      initialValue: 'POCZTA',
    },
    pocztexContent: {
      type: 'choice',
      choices: pocztexContent,
      label: 'Zawartość przesyłki',
      validate: ['required'],
      initialValue: 'INNA',
    },
    pocztexContentOther: {
      type: 'string',
      label: 'Zawartość przesyłki (inna)',
      validate: ['required'],
      fullWidth: true,
      initialValue: 'zamówienie TPN',
    },
    insurance: {
      type: 'number',
      label: 'Ubezpieczenie',
      hint: 'Kwota w zł',
      initialValue: 1000,
    },
  },
  PACZKA_ZAGRANICZNA: {
    bufor: {
      type: 'resource',
      endpoint: `/api/shop_elektroniczny_nadawca_bufors?pagination=false&exists[finishedDate]=false`,
      titleAccessor: `selectTitle`,
      label: 'Pakiet',
      validate: ['required'],
    },
    category: {
      type: 'choice',
      choices: categoryChoices,
      label: 'Kategoria',
      validate: ['required'],
    },
    weight: {
      type: 'number',
      label: 'Masa[g]',
      validate: ['required'],
    },
  },
}

export default schema
