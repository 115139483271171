import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { Title } from 'components/Title'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { standard } from 'components/table/_columns/standard'
import { DatetimeCell } from 'components/table/cells/DatetimeCell'
import { ModuleCell } from './table/cells/ModuleCell'
import { MethodCell } from './table/cells/MethodCell'
import { translate } from '_helpers/translate'
import schema from '_schema/registryEntry'
import { TitleCell } from './table/cells/TitleCell'
import { isObjectEmpty } from '../../_helpers/isObjectEmpty'
import globalRoutes from 'pages/routes'
import { useSelector } from 'react-redux'
import { NotFound } from '../NotFound'
import { hasResourceAccess } from '../../_helpers/hasResourceAccess'
import { Route } from 'react-router-dom'
import routes from './routes'

export const Collection = () => {
  const profile = useSelector(state => state.profile)

  const accessableRoutes = useMemo(
    () =>
      !isObjectEmpty(profile)
        ? [
            ...globalRoutes(),
            {
              type: Route,
              render: props => <NotFound {...props} />,
              path: '/*',
            },
          ].filter(route => hasResourceAccess(route.resourceAccess, profile))
        : [],
    [profile]
  )

  const columns = useMemo(
    () => [
      {
        ...standard('createdAt'),
        header: 'Czas',
        width: '12%',
        Cell: DatetimeCell,
      },
      {
        ...standard('username'),
        width: '12%',
        header: 'E-mail',
      },
      {
        accessor: 'requestUri',
        header: translate('T_GENERAL_MODULE'),
        filterable: false,
        sortable: false,
        width: '12%',
        Cell: ModuleCell,
        routes: accessableRoutes,
      },
      /*
      {
        accessor: 'resourceUuid',
        header: translate('T_GENERAL_RECORD'),
        filterable: true,
        sortable: false,
        width: '10%',
      },*/
      {
        accessor: 'title',
        header: 'Wpis / strona',
        filterable: true,
        sortable: false,
        width: '26%',
        Cell: TitleCell,
        routes: accessableRoutes,
      },
      {
        accessor: 'type.trans',
        header: 'Pole / blok',
        filterable: false,
        sortable: false,
        width: '12%',
      },
      {
        accessor: 'requestMethod',
        header: translate('T_GENERAL_OPERATION'),
        filterable: false,
        sortable: false,
        width: '12%',
        Cell: MethodCell,
      },
      { ...standard('ip'), width: '12%' },
    ],
    []
  )

  return (
    <Paper>
      <Title>{translate(routes().index.title)}</Title>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        autoWidth={false}
        defaultSorters={{ createdAt: 'desc' }}
        parameters={[
          {
            name: 'username',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'resourceUuid',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'ip',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'title',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[username]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ip]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
