import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from './components/table/_columns/operations'
import { DateCell } from 'components/table/cells/DateCell'
import { IsLicenceExpiredCell } from './components/table/cells/IsLicenceExpiredCell'
import { DoubletCell } from './components/table/cells/DoubletCell'
import { BooleanFilter } from 'components/table/filters/BooleanFilter'
import { translate } from '_helpers/translate'
import schema from '_schema/seaEyeHorse'
import routes from './routes'
import { ControlList } from './components/ControlList'
import { exportselected } from './components/table/exportselected'

export const Collection = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_GUIDE_NAME'),
        accessor: 'name',
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        header: translate('Passport number'),
        accessor: 'passportNumber',
        filterable: true,
        sortable: true,
        width: '12%',
      },
      {
        header: translate('Chip number'),
        accessor: 'chipNumber',
        filterable: true,
        sortable: true,
        width: '12%',
      },
      {
        header: translate('Registered at'),
        accessor: 'registeredAt',
        filterable: true,
        sortable: true,
        Cell: DateCell,
        width: '10%',
      },
      {
        header: translate('Last examined at'),
        accessor: 'lastExaminedAt',
        filterable: true,
        sortable: true,
        Cell: DateCell,
        width: '12%',
      },
      {
        header: translate('Withdrawn at'),
        accessor: 'withdrawnAt',
        filterable: true,
        sortable: true,
        Cell: DateCell,
        width: '10%',
      },
      {
        header: translate('Dublet'),
        accessor: 'doublet',
        Cell: DoubletCell,
        width: '10%',
      },
      {
        header: translate('Is licence expired'),
        accessor: 'isLicenceExpired',
        filterable: true,
        sortable: true,
        Cell: IsLicenceExpiredCell,
        width: '5%',
      },
      {
        header: translate('Owners'),
        accessor: 'fiacresTitles',
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        ...operations('title', dispatch, history),
        width: '8%',
      },
    ],
    [dispatch, history]
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_SEA_EYE_HORSE_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >
        <div style={{width:400}}>
          {translate('T_MODULE_SEA_EYE_HORSE')}
          <ControlList />
        </div>
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        selectable={[exportselected.go()]}
        customResourceSchema={schema.resource}
        customFilters={[
          {
            name: 'doublet[passportNumber]',
            title: 'Dublet paszportu',
            Filter: BooleanFilter,
          },
          {
            name: 'doublet[chipNumber]',
            title: 'Dublet chipa',
            Filter: BooleanFilter,
          },
        ]}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        parameters={[
          {
            name: 'name',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'passportNumber',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'chipNumber',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'registeredAt',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'lastExaminedAt',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'withdrawnAt',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'isLicenceExpired',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'fiacresTitles',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'doublet[passportNumber]',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'doublet[chipNumber]',
            in: 'query',
            required: false,
            type: 'boolean',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[name]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[passportNumber]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[chipNumber]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[registeredAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[lastExaminedAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[withdrawnAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[isLicenceExpired]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[fiacresTitles]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ord]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
