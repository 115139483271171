import {
  text,
  textWithPhoto,
  textWithVideo,
  //textWithLink,
  gallery,
  files,
  //faq,
  //logotypes,
  anchors,
  alert,
  banner1,
  banner2,
  links,
  list,
  // newsletter,
  // product,
  // trails,
  column,
  // crew,
  // miginfo,
  // formsetBlock,
  // tourismInformationNotices,
  // trailConditions,
  finalist,
  formsetContest,
  contestAuto,
  // embed
} from '_schema/_blocks'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockAnchorEndpoint,
  blockFileEndpoint,
  blockLinkEndpoint,
  blockLinkThumbEndpoint,
  blockListItemEndpoint,
  blockListItemThumbEndpoint
) => ({
  types: [
    text(),
    textWithPhoto(photoEndpoint, blockThumbEndpoint),
    textWithVideo(photoEndpoint, blockThumbEndpoint),
    //textWithLink(),
    gallery(),
    files(),
    //faq(),
    //logotypes(),
    anchors(blockAnchorEndpoint),
    alert(),
    banner1(photoEndpoint, blockThumbEndpoint),
    banner2(photoEndpoint, blockThumbEndpoint),
    links(photoEndpoint, blockLinkEndpoint, blockLinkThumbEndpoint),
    list(photoEndpoint, blockListItemEndpoint, blockListItemThumbEndpoint),
    column(blockListItemEndpoint),
    // newsletter(blockListItemEndpoint),
    // product(photoEndpoint, blockThumbEndpoint),
    // trails(photoEndpoint, blockThumbEndpoint, blockFileEndpoint),
    // crew(),
    formsetContest(photoEndpoint, blockThumbEndpoint),
    contestAuto(),
    // miginfo(),
    // tourismInformationNotices(),
    // trailConditions(),
    finalist(photoEndpoint, blockListItemEndpoint, blockListItemThumbEndpoint, '#ContestBlockListItem'),
    // embed(),
  ],
})

export default schema
