import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from './table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/customer'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_FIRST_NAME'),
        accessor: `firstName`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        header: translate('T_GENERAL_SURNAME'),
        accessor: `lastName`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        header: translate('T_GENERAL_PHONE_NUMBER'),
        accessor: `phone`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        header: translate('T_GENERAL_EMAIL'),
        accessor: `email`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        ...operations(`email`, true, true, true, true, false),
        width: '10%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_CUSTOMER_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >
        {translate('T_MODULE_CUSTOMER')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'email',
            in: 'query',
            type: 'string',
          },
          {
            name: 'firstName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'lastName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'phone',
            in: 'query',
            type: 'string',
          },
          {
            name: 'deleted',
            in: 'query',
            required: false,
            type: 'boolean',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[email]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[firstName]',
            in: 'query',
            type: 'string',
          },
        ]}
        defaultFilters={{
          deleted: false,
        }}
        numerable={false}
        orderable={false}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
