import {
  text,
  textWithPhoto,
  files,
  entitiesMap,
  articlesList,
} from '_schema/_blocksBip'

const schema = (photoEndpoint, blockThumbEndpoint, blockFileEndpoint) => ({
  types: [
    text(),
    textWithPhoto(photoEndpoint, blockThumbEndpoint),
    files(photoEndpoint, blockFileEndpoint),
    entitiesMap(),
    articlesList(),
  ],
})

export default schema
