import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from './table/operations'
import { translate } from '_helpers/translate'
import { MainCellSave } from './table/cells/MainCellSave'
import { MainCellSendcount } from './table/cells/MainCellSendcount'
import schema from '_schema/accommodationNotice'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `mailTitle`,
        filterable: true,
        sortable: true,
        width: '60%',
      },
      {
        header: translate('Wysłanych'),
        accessor: `sentCount`,
        filterName: 'sentCount',
        filterable: true,
        Cell: MainCellSendcount,
        width: '5%',
      },
      {
        header: translate('Data wysłania'),
        accessor: `sentDate`,
        filterName: 'sentDate',
        filterable: true,
        Cell: MainCellSave,
        width: '10%',
      },
      {
        ...operations(`mailTitle`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Nowa wiadomość')}
        buttonPath={routes().new.path}
      >
        {translate('Powiadomienia')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        orderable={false}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        // defaultFilters={{
        //   'exists[parent]': false,
        // }}
        // parameters={[
        //   {
        //     name: 'exists[parent]',
        //     in: 'query',
        //     required: false,
        //     type: 'boolean',
        //   },
        // ]}
      />
    </Paper>
  )
}
