import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/volunteeringAccommodation'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        filterable: true,
        sortable: true,
        width: '70%',
      },
      {
        header: translate('T_MODULE_VOLUNTEERING_VACANCIES'),
        accessor: `vacancies`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        ...operations(
          `translations.${LANGS_IRI.PL}.title`,
          true,
          true,
          true,
          true,
          false
        ),
        width: '10%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate(
          'T_MODULE_VOLUNTEERING_ACCOMMODATION_NEW_BUTTON'
        )}
        buttonPath={routes().new.path}
      >
        {translate('T_MODULE_VOLUNTEERING_ACCOMMODATION')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
