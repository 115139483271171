import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'
import { getApiUrl } from '_services/apiUrlProvider'
import { translate } from '_helpers/translate'
import { constants } from 'components/table/_state'

const performexportselected = (action, title) => ({
  action,
  title: translate(title),
  execute: (tableState, tableStateDispatch) => {
    let itemsUuidToSend = []

    const items = tableState.data.selected.map((isSelected, index) => {
      const visSelected =
        typeof isSelected === 'object' ? isSelected.value : isSelected

      if (!visSelected) {
        return tableState.data.items[index]
      }

      itemsUuidToSend.push(tableState.data.items[index].uuid)

      return tableState.data.items[index]
    })

    let url = `${getApiUrl()}/api/shoporder/status/change`

    setTimeout(() => {
      fetch(url, {
        method: 'POST',
        headers: {
          ...authHeader(),
        },
        body: JSON.stringify({
          items: itemsUuidToSend,
          action: action,
        }),
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Error')
          }
          return response.json()
        })
        .then(json => {

          tableStateDispatch({
            type: constants.RELOAD,
            payload: { action, items },
          })
        })
        .catch(e => {
          notification('error', 'Error, nie mozna zmienić', e)
          tableStateDispatch({
            type: constants.MASS_SELECT_ACTION_FAILURE,
            payload: { action },
          })
        })
    }, 500)
  },
})

export const statusselected = {
  status: (action = 'new', title = 'Nowa') =>
    performexportselected(action, title),
}
