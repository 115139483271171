import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import schema from '_schema/matterFormRequest'
import routes from './routes'
import { DateCell } from '../../components/table/cells/DateCell'
import { DatetimeCell } from '../../components/table/cells/DatetimeCell'
import { MatterTypeFilter } from 'pages/MatterFormPermission/components/MatterTypeFilter'
import { MatterStatusFilter2 } from 'pages/MatterFormPermission/components/MatterStatusFilter2'

export const CollectionAwaiting = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('Dane kontaktowe'),
        accessor: `contactName`,
        filterable: true,
        sortable: true,
        width: '20%',
      },
      {
        header: translate('Typ'),
        accessor: `type.trans`,
        translateAccessor: true,
        filterable: true,
        sortable: true,
        sorterName: 'type',
        width: '7%',
      },
      {
        header: translate('T_GENERAL_EMAIL'),
        accessor: `contactEmail`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        header: translate('T_GENERAL_PHONE_NUMBER'),
        accessor: `contactPhone`,
        filterable: true,
        sortable: true,
        width: '20%',
      },
      {
        header: translate('Od'),
        accessor: `dateFrom`,
        filterable: true,
        sortable: true,
        width: '20%',
        Cell: DateCell,
      },
      {
        header: translate('Do'),
        accessor: `dateTo`,
        filterable: true,
        sortable: true,
        width: '20%',
        Cell: DateCell,
      },
      {
        header: translate('Liczba osób'),
        accessor: `persons`,
        filterable: true,
        sortable: true,
        width: '20%',
      },
      {
        header: translate('Data dodania'),
        accessor: `createdAt`,
        filterable: true,
        sortable: true,
        width: '20%',
        Cell: DatetimeCell,
      },
      {
        header: translate('Data edycji'),
        accessor: `updatedAt`,
        filterable: true,
        sortable: true,
        width: '20%',
        Cell: DatetimeCell,
      },
      {
        header: translate('Kod'),
        accessor: `importUq`,
        filterable: true,
        sortable: true,
        width: '20%',
      },
      {
        header: translate('Status'),
        accessor: `status.trans`,
        translateAccessor: true,
        transTitle: true,
        width: '20%',
      },
      {
        ...operations(`contactName`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [reloadCollection, setReloadCollection] = useState(true)
  const customFilters = [
    {
      name: 'type',
      title: 'Typ sprawy',
      value: null,
      Filter: MatterTypeFilter,
    },
    {
      name: 'status',
      title: 'Status',
      value: null,
      Filter: MatterStatusFilter2,
    },
  ]

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={''}
        buttonPath={routes().new.path}
        hideButton={true}
      >
        {translate('Wnioski do rozpatrzenia')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        customFilters={customFilters}
        defaultSorters={{ createdAt: 'desc' }}
        defaultFilters={{
          'status[between]': '1..3',
        }}
        orderable={false}
        //selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        numerable={false}
        parameters={[
          {
            name: 'type',
            in: 'query',
            type: 'string',
          },
          {
            name: 'status',
            in: 'query',
            type: 'string',
          },
          {
            name: 'status[between]',
            in: 'query',
            type: 'string',
            value: false,
          },
          {
            name: 'contactName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'contactEmail',
            in: 'query',
            type: 'string',
          },
          {
            name: 'contactPhone',
            in: 'query',
            type: 'string',
          },
          {
            name: 'dateFrom',
            in: 'query',
            type: 'string',
          },
          {
            name: 'dateTo',
            in: 'query',
            type: 'string',
          },
          {
            name: 'createdAt',
            in: 'query',
            type: 'string',
          },
          {
            name: 'updatedAt',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'status[lt]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[updatedAt]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[dateFrom]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[dateTo]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[contactName]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[contactEmail]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[contactPhone]',
            in: 'query',
            type: 'string',
          },
        ]}
        reloadCollection={reloadCollection}
        setReloadCollection={setReloadCollection}
      />
    </Paper>
  )
}
