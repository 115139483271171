export const translations = {
  pl: {
    after: 'po',
    to: 'do',
    /* Inside application code */
    T_GENERAL_OPERATIONS: 'Operacje',
    T_GENERAL_EDIT: 'Edytuj',
    T_GENERAL_NEW: 'Dodaj',
    T_GENERAL_CLONE: 'Klonuj',
    T_GENERAL_CLONING: 'Klonuję...',
    T_GENERAL_PUBLISH_NEW_VERSION: 'Opublikuj nową wersję',
    T_GENERAL_PUBLISHING_NEW_VERSION: 'Publikuję...',
    T_GENERAL_PUBLISHED_NEW_VERSION: 'Opublikowano nową wersję',
    T_GENERAL_VERSION_CREATE: 'Archiwizuj',
    T_GENERAL_VERSION_CREATING: 'Archiwizuję...',
    T_GENERAL_VERSION_RESTORE: 'Przywróć wersję',
    T_GENERAL_VERSION_RESTORING: 'Przywracam wersję...',
    T_GENERAL_VERSION_CREATED_AT: 'Wersja z dnia',
    T_GENERAL_VIEW: 'Pokaż',
    T_GENERAL_DELETE: 'Usuń',
    T_GENERAL_DELETE_CONFIRM: 'Czy na pewno usunąć',
    T_GENERAL_DELETED: 'Rekord usunięty',
    T_GENERAL_CREATED_AT: 'Utworzono',
    T_GENERAL_UPDATED_AT: 'Ostatnia aktualizacja',
    T_GENERAL_CANCEL: 'Anuluj',
    T_GENERAL_CONFIRM: 'Potwierdź',
    T_GENERAL_STATUS_CHANGED: 'Zmieniono status',
    T_GENERAL_SAVE: 'Zapisz',
    T_GENERAL_SAVE_TO_KEEP_CHANGES: 'Zapisz aby zachować zmiany',
    T_GENERAL_SEARCH: 'Szukaj',
    T_GENERAL_ALL: 'Wszystkie',
    T_GENERAL_REFRESH: 'Odśwież',
    T_GENERAL_TOGGLE_MENU: 'Przełącznik menu',
    T_GENERAL_RESULT_SUCCESS: 'Udane',
    T_GENERAL_RESULT_FAILURE: 'Nieudane',
    T_GENERAL_NO: 'Nr',
    T_GENERAL_OPERATION_DATE: 'Data operacji',
    T_GENERAL_PRICE: 'Cena',
    T_GENERAL_GRADE: 'Ocena',
    T_GENERAL_AMOUNT: 'Ilość',
    T_GENERAL_MODULE: 'Moduł',
    T_GENERAL_RECORD: 'Rekord',
    T_GENERAL_OPERATION: 'Operacja',
    T_GENERAL_SHOW_ALL: 'Pokaż wszystkie',
    T_GENERAL_REQUIRED: 'Wymagany',
    T_GENERAL_DATE: 'Data',
    T_GENERAL_EMPTY: 'Brak',
    T_GENERAL_TITLE: 'Tytuł',
    T_GENERAL_INVALID_DATE: 'Niepoprawny format',
    T_GENERAL_INVALID_MIN_DATE: 'Data nie może być mniejsza niż 01.01.1990',
    T_GENERAL_INVALID_MAX_DATE: 'Data nie może być większa niż 01.01.2100',
    T_GENERAL_DESCRIPTION: 'Krótki opis',
    T_GENERAL_DESCRIPTION_HINT: 'Wyświetlany na detalu',
    T_GENERAL_DESCRIPTION_NORMAL: 'Opis',
    T_GENERAL_LEAD: 'Lead',
    T_GENERAL_LEAD_HINT: 'Wyświetlany na liście',
    T_GENERAL_SHORT_LEAD_TILE: 'Krótki opis na kafelku',
    T_GENERAL_LIST: 'Lista',
    T_GENERAL_PUBLICATION: 'Publikacja',
    T_GENERAL_CODE: 'Kod',
    T_GENERAL_TIME: 'Czas',
    T_GENERAL_ADMIN: 'Administrator',
    T_GENERAL_RECORD_LIST: 'Lista',
    T_GENERAL_RECORD_NEW: 'Dodaj',
    T_GENERAL_RECORD_NEW_BUTTON: 'Dodaj nowy rekord',
    T_GENERAL_RECORD_EDIT: 'Edycja',
    T_GENERAL_RECORD_SHOW: 'Pokaż',
    T_GENERAL_RECORD_PREVIEW: 'Podgląd',
    T_GENERAL_RECORD_VERSION_COLLECTION: 'Poprzednie wersje',
    T_GENERAL_RECORD_VERSION_VIEW: 'Podgląd',
    T_GENERAL_RECORD_VIEW: 'Wyświetl',
    T_GENERAL_ITEMS_EMPTY: 'Brak elementów',
    T_GENERAL_BLOCK: 'Bloki',
    T_GENERAL_BLOCK_TYPE_TEXT: 'Blok tekstowy',
    T_GENERAL_BLOCK_TYPE_TEXT_COLUMN1: 'Treść kolumny 1',
    T_GENERAL_BLOCK_TYPE_TEXT_COLUMN2: 'Treść kolumny 2',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO: 'Blok tekstowy ze zdjęciem',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_LINK: 'Blok tekstowy z linkiem',
    T_GENERAL_BLOCK_TYPE_GALLERY: 'Blok z galerią',
    T_GENERAL_BLOCK_TYPE_FILES: 'Blok z plikami',
    T_GENERAL_BLOCK_TYPE_FAQ: 'Blok z FAQ',
    T_GENERAL_BLOCK_TYPE_LOGOTYPES: 'Blok z logotypami',
    T_GENERAL_BLOCK_TYPE_AUDIO: 'Blok z audio',
    T_GENERAL_BLOCK_TYPE_ETR: 'Blok z ETR',
    T_GENERAL_BLOCK_TYPE_CITATION: 'Blok z cytatem',
    T_GENERAL_BLOCK_TYPE_AUTHOR: 'Blok z autorem',
    T_GENERAL_BLOCK_TYPE_ISSUU: 'Blok z ISSUU',
    T_GENERAL_BLOCK_TYPE_ENTITIES_MAP: 'Blok z mapą podmiotów',
    T_GENERAL_BLOCK_TYPE_ARTICLES_LIST: 'Blok z listą artykułów',
    T_GENERAL_BLOCK_TYPE_EMBED: 'Blok embed',
    T_GENERAL_BLOCK_TYPE_SHOP_BENEFITS: 'Blok z benefitami',
    T_GENERAL_BLOCK_TYPE_SHOP_CAROUSEL: 'Blok z produktami',
    T_GENERAL_BLOCK_TYPE_SHOP_BANNER1: 'Blok z 1 banerem',
    T_GENERAL_BLOCK_TYPE_SHOP_BANNER2: 'Blok z 2 banerami',
    T_GENERAL_BLOCK_TYPE_SHOP_PHOTO_VIDEO: 'Blok z video',
    T_GENERAL_CHOOSE: 'Wybierz',
    T_GENERAL_MISSING_TITLE: '<Brak tytułu>',
    T_GENERAL_SUPER_ADMIN: 'Super Admin',
    T_GENERAL_REDACTOR: 'Redaktor',
    T_GENERAL_TRANSLATION: 'Tłumaczenie',
    T_GENERAL_MANUAL_ORDER: 'Manualna kolejność',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_PREVIOUS:
      'Upuść tu żeby przenieść na poprzednią stronę',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_NEXT:
      'Upuść tu żeby przenieść na następną stronę',
    T_GENERAL_DRAG_HANDLE: 'Przytrzymaj i przeciągnij, aby zmienić kolejność',
    T_GENERAL_UPLOAD: 'Upload pliku',
    T_GENERAL_UPLOAD_MULTIPLE: 'Upload plików',
    T_GENERAL_PHOTO: 'Zdjęcie',
    T_GENERAL_PHOTO2: 'Zdjęcie 2',
    T_GENERAL_SEO_PHOTO: '[SEO] Meta zdjęcie',
    T_GENERAL_VIDEO: 'Film',
    T_GENERAL_AUDIO: 'Plik audio',
    T_GENERAL_IMAGE_ADD: 'Dodaj zdjęcie',
    T_GENERAL_IMAGE_CHANGE: 'Wymień zdjęcie',
    T_GENERAL_IMAGE_REMOVE: 'Usuń zdjęcie',
    T_GENERAL_IMAGE_REMOVE_CONFIRM: 'Czy na pewno usunąć zdjęcie?',
    T_GENERAL_PHOTO_THUMBS: 'Kadry',
    T_GENERAL_PHOTO_THUMBS_EMPTY: 'Brak kadrów',
    T_GENERAL_PHOTO_THUMBS_SAVE: 'Zapisz kadr',
    T_GENERAL_PHOTO_THUMBS_RESET: 'Resetuj kadr',
    T_GENERAL_PHOTO_THUMBS_CHANGE_FILE: 'Zmień plik dla kadru',
    'T_THUMB_API/PAGE_THUMBS_MAIN': 'Kadr główny',
    'T_THUMB_API/PAGE_THUMBS_LIST': 'Kadr na listę',
    'T_THUMB_API/PAGE_BLOCK_THUMBS_MAIN': 'Kadr główny',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_LIST': 'Kadr',
    'T_THUMB_API/PACKAGE_GALLERY_ITEM_THUMBS_LISTH': 'Kadr pionowy',
    'T_THUMB_API/HOME_BANNER_ITEM_THUMBS_MAIN': 'Kadr główny',
    T_THUMB_API_MEDIA_PICTOGRAM: 'Piktogram',
    T_THUMB_API_MEDIA_BANNER: 'Blok z 1 banerem',
    T_THUMB_API_MEDIA_BANNER2: 'Blok z 2 banerami (1 zdjęcie)',
    T_THUMB_API_MEDIA2_BANNER2: 'Blok z 2 banerami (2 zdjęcie)',
    T_THUMB_API_MEDIA_PRODUCT: 'Blok z produktem',
    T_THUMB_API_MEDIA_SINGLE_PHOTO: 'Blok ze zdjęciem (1 zdjęcie)',
    T_THUMB_API_MEDIA_DOUBLE_PHOTO: 'Blok ze zdjęciem (2 zdjęcia)',
    T_THUMB_API_MEDIA2_SINGLE_PHOTO: 'Blok ze zdjęciem (1 zdjęcie)',
    T_THUMB_API_MEDIA2_DOUBLE_PHOTO: 'Blok ze zdjęciem (2 zdjęcia)',
    T_THUMB_API_MEDIA_DETAIL: 'Detal artykułu',
    T_THUMB_API_MEDIA_DETAIL_MOBILE: 'Detal artykułu w wersji mobilnej',
    T_THUMB_API_MEDIA_FEATURED: 'Wyróżniona lista',
    T_THUMB_API_MEDIA_THUMB: 'Miniatura',
    T_THUMB_API_MEDIA_THUMB_PARK_DETAIL: 'Miniatura w detalu parku',
    T_THUMB_API_MEDIA_LIST: 'Lista',
    T_THUMB_API_MEDIA_ICON: 'Ikona',
    T_THUMB_API_MEDIA_SHOP_CAROUSEL: 'Lista produktów',
    T_THUMB_API_MEDIA_SHOP_BANNER2: '1 baner',
    T_THUMB_API_MEDIA2_SHOP_BANNER2: '2 baner',
    T_THUMB_API_IMAGE_CROPPED: 'Miniatura',
    T_GENERAL_EXPAND_ON: 'zwiń wszystkie',
    T_GENERAL_EXPAND_OFF: 'rozwiń wszystkie',
    T_GENERAL_EXPAND_BLOCKS: 'bloki',
    T_GENERAL_BLOCK_COLLECTION_TITLE: 'Bloki śródtekstowe',
    T_GENERAL_BLOCK_TYPE_LINKS_LINK: 'Link',
    T_GENERAL_BLOCK_TYPE_LINKS_TARGET: 'Otworzyć w nowej karcie',
    T_GENERAL_BLOCK_TYPE_LINKS: 'Blok z linkami',
    T_GENERAL_BLOCK_TYPE_LINKS_LINKS: 'Linki',
    T_GENERAL_BLOCK_TYPE_TOURISM_INFORMATION_NOTICES_TYPE: 'Typ informacji',
    T_BLOCK_SHOP_CAROUSEL_IS_NEW: 'Nowości',
    T_BLOCK_SHOP_CAROUSEL_IS_BESTSELLER: 'Bestsellery',
    T_BLOCK_SHOP_CAROUSEL_IS_SUGGESTED: 'Sugerowane',
    T_GENERAL_ALT: 'Znacznik alt zdjęcia',
    T_GENERAL_ALT2: 'Znacznik alt zdjęcia 2',
    T_GENERAL_FILE: 'Plik',
    T_GENERAL_FILE_ADD: 'Dodaj plik',
    T_GENERAL_FILE_CHANGE: 'Wymień plik',
    T_GENERAL_FILE_REMOVE: 'Usuń plik',
    T_GENERAL_FILE_REMOVE_CONFIRM: 'Czy na pewno usunąć plik?',
    T_GENERAL_FILE_VIDEO: 'Plik video',
    T_GENERAL_NAME: 'Nazwa',
    T_GENERAL_SEND: 'Wyślij',
    T_GENERAL_CHOSEN_FILE: 'Wybrany plik',
    T_GENERAL_CHOSEN_FILES: 'Wybrane pliki',
    T_GENERAL_NONE: 'brak',
    T_GENERAL_YOUTUBE_CODE: 'Kod filmu youtube',
    T_GENERAL_YOUTUBE_CODE_INFO:
      'W przypadku wgrania filmu i dodania kodu filmu z YouTube, wyświetli się film z YouTube',
    T_GENERAL_VIDEO_MASK: 'Maska filmu',
    T_GENERAL_MEDIA_PHOTO: 'Zdjęcie',
    T_GENERAL_MEDIA_YOUTUBE: 'Film youtube',
    T_GENERAL_MASS_SELECT_ACTION: 'Co zrobić z zaznaczonymi rekordami',
    T_GENERAL_MASS_SELECT_CHOOSE: 'Wybierz',
    T_GENERAL_MASS_SELECT_PUBLISH: 'Publikuj',
    T_GENERAL_MASS_SELECT_UNPUBLISH: 'Od publikuj',
    T_GENERAL_ENABLE: 'Włącz',
    T_GENERAL_DISABLE: 'Wyłącz',
    T_GENERAL_OPENFORM_ADMIN_GROUP_IS_OPENFORM: '[OF] Prawa openform',
    T_GENERAL_OPENFORM_PAGE_IDNAME: '[OF] Id name',
    T_GENERAL_OPENFORM_PAGE_IS_STATABLE: '[OF] Publikowana',
    T_GENERAL_OPENFORM_PAGE_IS_EDITABLE: '[OF] Edytowalna',
    T_GENERAL_OPENFORM_PAGE_IS_DELETABLE: '[OF] Usuwalna',
    T_GENERAL_OPENFORM_PAGE_IS_SUBPAGEABLE: '[OF] Podstrony',
    T_GENERAL_SAVE_FORM_TO_EDIT: 'Zapisz formularz, aby edytować',
    T_GENERAL_PASSWORD_WAS_RESETTED:
      'Twoje hasło zostało zresetowane przez administratora. Ustaw nowe hasło, a następnie przeloguj się, aby odzyskać dostęp.',
    T_GENERAL_EDIT_DESCRIPTION: 'edytuj opis',
    T_GENERAL_BACK_TO_LIST: 'Wróć na listę',
    T_GENERAL_NEXT: 'Następny',
    T_GENERAL_PREVIOUS: 'Poprzedni',
    T_GENERAL_FULL_SCREEN: 'Full screen',
    T_GENERAL_LOGIN: 'Zaloguj',
    T_GENERAL_LOGOUT: 'Wyloguj',
    T_GENERAL_REMEMBER_ME: 'Zapamiętaj mnie',
    T_GENERAL_FORGOT_PASSWORD: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TITLE: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TYPE_EMAIL: 'Twój adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_SEND_LINK: 'Wyślij link',
    T_GENERAL_FORGOT_PASSWORD_VALIDATION_EMAIL: 'Niepoprawny adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_LINK_SENT:
      'Link wysłany. Sprawdź swój adres e-mail',
    T_GENERAL_NEW_PASSWORD_TITLE: 'Nadaj nowe hasło',
    T_GENERAL_NEW_PASSWORD_TYPE_NEW: 'Wpisz nowe hasło',
    T_GENERAL_NEW_PASSWORD_RETYPE_NEW: 'Powtórz nowe hasło',
    T_GENERAL_NEW_PASSWORD_CHANGE: 'Zmień hasło',
    T_GENERAL_NEW_PASSWORD_RESETTED: 'Hasło zostało zmienione',
    T_GENERAL_BACK_TO_LOGIN: 'Powrót do logowania',
    T_GENERAL_PAGE: 'Strona',
    T_GENERAL_MAP: 'Mapa',
    T_GENERAL_MAP_HINT:
      'Wpisz pełną lokalizację lub częściową i wybierz z listy. Marker na mapie zostanie automatycznie ustawiony',
    T_GENERAL_MAP_INPUT: 'Wpisz lokalizację',
    T_GENERAL_NORMAL_VIEW: 'Powrót do poprzedniego widoku',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_CHOSEN: 'Dodajesz % plików',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_INFO: 'Nadaj wszystkim dodawanym:',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING: 'Dodaję',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING_FROM: 'z',
    T_GENERAL_CONTEXTUAL_HINT: 'Pomoc kontekstowa',
    T_GENERAL_YOU_ARE_LOGGED_AS: 'Jesteś zalogowany jako',
    T_GENERAL_PREVIEW: 'Podgląd',
    T_GENERAL_MASS_UPLOAD_DRAG_AND_DROP:
      'Przeciągnij i upuść lub kliknij, żeby wybrać',
    T_GENERAL_PLANNED_PUBLISH_DATE_TIME: 'Planowana publikacja',
    T_GENERAL_PUBLISH: 'Publikuj',
    T_GENERAL_UNPUBLISH: 'Od publikuj',
    T_GENERAL_VERSION: 'Wersja',
    T_GENERAL_DOWNLOAD_YOUTUBE_THUMBNAIL: 'Pobierz zaślepkę z youtube',
    T_GENERAL_AUTHOR: 'Autor',
    T_GENERAL_DATE_FROM: 'Data od',
    T_GENERAL_DATE_TO: 'Data do',
    T_GENERAL_PUBLICATION_DATE: 'Data publikacji',
    T_GENERAL_CATEGORY: 'Kategoria',
    T_GENERAL_CATEGORIES: 'Kategorie',
    T_GENERAL_LINKED_NEWS: 'Powiązane aktualności',
    T_GENERAL_FIRST_NAME: 'Imię',
    T_GENERAL_FULL_NAME: 'Imię i nazwisko',
    T_GENERAL_SURNAME: 'Nazwisko',
    T_GENERAL_PHONE_NUMBER: 'Numer telefonu',
    T_GENERAL_COMMENT: 'Komentarz',
    T_GENERAL_FAX: 'Numer fax',
    T_GENERAL_PESEL: 'PESEL',
    T_GENERAL_NIP: 'Numer NIP',
    T_GENERAL_ADD_ARTICLE: 'Dodaj artykuł',
    T_GENERAL_EXTERNAL_LINK: 'Link (otwiera zewnętrzną stronę)',
    T_GENERAL_HIGHLIGHTED: 'Wyróżnienie',
    T_GENERAL_HOMEPAGE_TITLE: 'Tytuł na stronie głównej',
    T_GENERAL_SHOW_ON_HOMEPAGE: 'Pokaż na stronie głównej',
    T_GENERAL_GALLERY: 'Galeria',
    T_GENERAL_ARTICLES: 'Artykuły',
    T_GENERAL_ADDRESS: 'Adres',
    T_GENERAL_ANCHOR:
      'Nazwa kotwicy przypisanej do danego bloku np "news-list"',
    T_GENERAL_PHOTO_MINIMUM_DIMENSIONS: 'Minimalne wymiary',
    T_GENERAL_STATUS: 'Status',
    T_GENERAL_FRONT_ROUTE: 'Link',
    T_GENERAL_FRONT_ADMIN_ROUTE: 'Podgląd dla administratora',
    T_LINKS_FACEBOOK: 'Link Facebook',
    T_LINKS_YOUTUBE: 'Link Youtube',
    T_LINKS_INSTAGRAM: 'Link Instagram',
    T_LOGIN_DATE: 'Data logowania',
    T_TABLE_FIRST_PAGE: 'Pierwsza strona',
    T_TABLE_LAST_PAGE: 'Ostatnia strona',
    T_TABLE_RESET: 'Wyczyść ustawienia tabeli',
    T_TABLE_NO_RESULTS: 'Brak wyników',
    T_MODULE_DASHBOARD: 'Dashboard',
    T_MODULE_SETTINGS: 'Ustawienia',
    T_MODULE_PROFILE: 'Profil',
    T_MODULE_TRANSLATOR_ENTRIES: 'Lista tłumaczeń',
    T_MODULE_TRANSLATOR_ENTRIES_NEW_BUTTON: 'Dodaj nowe tłumaczenie',
    T_MODULE_ADMINS: 'Lista administratorów',
    T_MODULE_ADMINS_NEW_BUTTON: 'Dodaj nowego administratora',
    T_MODULE_ADMIN_GROUPS: 'Lista grup administratorskich',
    T_MODULE_ADMIN_GROUPS_NEW_BUTTON: 'Dodaj nową grupę administratorską',
    T_MODULE_ADMIN_HOSTNAMES_SELECT: 'Wybierz parki',
    T_MODULE_ARTICLES: 'Lista artykułów',
    T_MODULE_ARTICLES_NEW_BUTTON: 'Dodaj nowy artykuł',
    T_MODULE_PAGES: 'Lista stron',
    T_MODULE_PAGES_NEW_BUTTON: 'Dodaj nową stronę',
    T_MODULE_PAGES_EDIT_STRUCTURE: 'Edycja struktury',
    T_MODULE_PAGES_SHOW_ALL_CHILDREN: 'Rozwiń wszystko',
    T_MODULE_PAGES_HIDE_ALL_CHILDREN: 'Zwiń wszystko',
    T_MODULE_PAGES_ADD_SUBPAGE: 'Dodaj podstronę',
    T_MODULE_PAGES_SUBPAGE_UNDER_PREVIOUS_PAGE:
      'Przenieś jako podstronę strony wyżej',
    T_MODULE_PACKAGE_GALLERIES: 'Lista galerii',
    T_MODULE_PACKAGE_GALLERIES_NEW_BUTTON: 'Dodaj nową galerię',
    T_MODULE_PACKAGE_GALLERY_ITEMS: 'Lista mediów',
    T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_BUTTON: 'Dodaj nowe zdjęcie',
    T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_MULTIPLE_BUTTON: 'Dodaj wiele zdjęć',
    T_MODULE_PACKAGE_FILES: 'Lista pakietów plików',
    T_MODULE_PACKAGE_FILES_NEW_BUTTON: 'Dodaj nowy pakiet',
    T_MODULE_PACKAGE_FILE_ITEMS_HEADER_TITLE: 'Pliki:',
    T_MODULE_PACKAGE_FILE_ITEMS_EXPAND_TITLE: 'pliki',
    T_MODULE_PACKAGE_FAQS: 'Lista pakietów pytań',
    T_MODULE_PACKAGE_FAQ_ITEMS_EXPAND_TITLE: 'pytania',
    T_MODULE_PACKAGE_FAQ_ITEMS_HEADER_TITLE: 'Pytania',
    T_MODULE_PACKAGE_FAQS_NEW_BUTTON: 'Dodaj nowy pakiet',
    T_MODULE_PACKAGE_LOGOTYPE: 'Lista pakietów logotypów',
    T_MODULE_PACKAGE_LOGOTYPES_NEW_BUTTON: 'Dodaj pakiet logotypów',
    T_MODULE_NEWS: 'Aktualności',
    T_MODULE_NEWS_CATEGORY: 'Kategorie',
    T_MODULE_NEWS_NEW_BUTTON: 'Dodaj aktualność',
    T_MODULE_NEWS_CATEGORY_NEW_BUTTON: 'Dodaj kategorię',
    T_MODULE_NEWS_CATEGORY_ACCESS: 'Aktualności - kategorie',
    T_MODULE_EVENT: 'Wydarzenia',
    T_MODULE_EVENT_NEW_BUTTON: 'Dodaj wydarzenie',
    T_MODULE_EVENT_LOCATION: 'Lokacja',
    T_MODULE_EVENT_ADDRESS: 'Adres',
    T_MODULE_EVENT_DATE_FROM:
      'Data od (Wydarzenie bez daty nie będzie widoczne!)',
    T_MODULE_EVENT_CATEGORY: 'Kategorie',
    T_MODULE_EVENT_CATEGORY_NEW_BUTTON: 'Dodaj kategorię',
    T_MODULE_EVENT_SUGGESTED: 'Sugerowane wydarzenie',
    T_MODULE_MATTER: 'Załatw sprawę',
    T_GENERAL_FAQ: 'Pytanie',
    'Faqs package title': 'Tytuł zestawu FAQ',
    'Faq packages': 'FAQ',
    'Logotypes title': 'Tytuł zestawu logotypów',
    'Logotype packages': 'Logotypy',
    'Select package FAQ': 'Wybierz zestaw faq',
    'Select package files': 'Wybierz zestaw plików',
    'Select package gallery': 'Wybierz galerię',
    'Select package logotype': 'Wybierz zestaw logotypów',
    // T_GENERAL_BLOCK_TYPE_PACKAGE_FAQ: 'Blok z FAQ',
    'News list': 'Lista',
    'News categories': 'Kategorie',
    T_RESOURCE_PACKAGE_FAQ: 'Zestawy FAQ',
    T_RESOURCE_PACKAGE_FILE: 'Zestawy plików',
    T_RESOURCE_PACKAGE_GALLERY: 'Zestawy galerii',
    T_RESOURCE_PACKAGE_GALLERY_ITEM: 'Zestawy galerii - zdjęcia',
    T_RESOURCE_PACKAGE_LOGOTYPE: 'Zestawy logotypów',
    T_RESOURCE_FOOTER: 'Stopka',
    T_RESOURCE_TRAIL: 'Trasy',
    T_RESOURCE_EVENT: 'Wydarzenia',
    T_RESOURCE_PARK: 'Parki',
    T_RESOURCE_VOLUNTEERING: 'Wolontariat',
    T_RESOURCE_SHOP: 'Sklep',
    T_RESOURCE_BIP: 'Bip',
    T_RESOURCE_TOURISM_NOTICE: 'Komunikat turystyczny',
    T_RESOURCE_CONFIG: 'Konfiguracja',
    T_RESOURCE_SOCIAL_MEDIA: 'Social media',
    T_RESOURCE_MAIN_MENU: 'Menu główne',
    T_RESOURCE_FORMSET: 'Formularze',
    T_RESOURCE_CREW: 'Pracownicy',
    T_RESOURCE_LESSON: 'Zajęcia',
    T_RESOURCE_SEO_REDIRECT: 'Przekierowania SEO',
    T_MENU_PACKAGES: 'Pakiety',
    T_MENU_REGISTRIES: 'Rejestry',
    T_MENU_NEWS: 'Aktualności',
    T_MENU_PORTAL: 'Portal',
    T_MENU_CUSTOMERS: 'Klienci',
    T_MENU_CUSTOMER_GROUP: 'Grupy',
    T_MENU_EVENTS: 'Wydarzenia',
    T_MENU_TRAILS: 'Trasy',
    T_MENU_VOLUNTEERING: 'Wolontariat',
    T_MENU_SHOP: 'Sklep',
    T_MENU_BIP: 'BIP',
    T_MENU_LESSON: 'Zajęcia',
    T_REQUEST_METHOD_GET: 'Wyświetlenie',
    T_REQUEST_METHOD_PUT: 'Edycja',
    T_REQUEST_METHOD_POST: 'Utworzenie',
    T_REQUEST_METHOD_DELETE: 'Usunięcie',
    T_FORM_PROFILE_TITLE: 'Dane',
    T_FORM_CHANGE_PASSWORD_TITLE: 'Zmiana hasła',
    T_FORM_FIELD_EMAIL: 'E-mail',
    T_FORM_FIELD_PASSWORD: 'Hasło',
    T_FORM_FIELD_NEW_PASSWORD: 'Nowe hasło',
    T_FORM_FIELD_NEW_PASSWORD_REPEAT: 'Powtórz nowe hasło',
    T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD: 'Podaj nowe hasło',
    T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD: 'Powtórz nowe hasło',
    T_FORM_FIELD_RESOURCE_ACCESS: 'Dostęp do modułów',
    T_FORM_FIELD_BOOLEAN_ON: 'Tak',
    T_FORM_FIELD_BOOLEAN_OFF: 'Nie',
    T_FORM_INCORRECT: 'Niepoprawny formularz',
    T_FORM_SUCCESS: 'Zapisano',
    T_FORM_RECORD_UPDATED: 'Rekord zaktualizowany',
    T_FORM_RECORD_CREATED: 'Rekord dodany',
    T_FORM_RECORD_CLONED: 'Rekord sklonowany',
    T_FORM_RECORD_MULTIPLE_CREATED: 'Rekordy dodane',
    T_FORM_RECORD_MULTIPLE_UPDATED: 'Rekordy zaktualizowane',
    T_FORM_RECORD_UPLOADED: 'Rekord wgrany',
    T_FORM_RECORD_VERSION_CREATED: 'Wersja rekordu utworzona',
    T_FORM_RECORD_VERSION_RESTORED: 'Wersja rekordu przywrócona',
    T_FORM_CONTEXTUAL_HINTS_ON: 'Pomoc kontekstowa włączona',
    T_FORM_CONTEXTUAL_HINTS_OFF: 'Pomoc kontekstowa wyłączona',
    T_FORM_SAVE: 'Zapisz',
    T_FORM_SAVE_AND_STAY: 'Zapisz i pozostań',
    T_FORM_ACCEPT: 'Akceptuj',
    T_FORM_CANCEL: 'Anuluj',
    T_FORM_RECORD_CHANGED:
      'Rekord został zmieniony przez innego administratora. Sprawdź zmiany i zaakceptuj.',
    T_VALIDATION_REQUIRED: 'Pole wymagane',
    T_VALIDATION_EMAIL: 'Niepoprawny e-mail',
    T_VALIDATION_PHONE: 'Niepoprawny numer telefonu',
    T_VALIDATION_MONEY: 'Niepoprawny format',
    T_VALIDATION_PASSWORD:
      'Hasło musi składać się z min. 8 znaków (duża litera, mała litera, cyfra, znak specjalny)',
    T_VALIDATION_PASSWORDS_DO_NOT_MATCH: 'Hasła nie zgadzają się',
    T_VALIDATION_LENGTH: 'To pole musi się składać z min. % znaków',
    T_VALIDATION_MAX_UPLOAD_SIZE: 'Maksymalny rozmiar pliku to %',
    T_VALIDATION_MAX_UPLOAD_MULTIPLE_SIZE:
      'Maksymalny rozmiar wszystkich plików to %',
    T_VALIDATION_MIN_VALUE: 'Minimalna wartość to %',
    T_VALIDATION_MAX_VALUE: 'Maksymalna wartość to %',
    T_404_TITLE: 'Strona nie istnieje',
    T_404_TEXT:
      'Wygląda na to, że kliknąłeś niedziałający link lub wprowadziłeś adres URL, który nie istnieje na tej witrynie.',
    T_404_BACK: 'Powrót na stronę główną',
    T_SETTINGS_MODE_LIGHT: 'Przejdź na tryb jasny',
    T_SETTINGS_MODE_DARK: 'Przejdź na tryb ciemny',
    T_FRONT_PREVIEW_ERROR_TITLE: 'Błąd',
    T_FRONT_PREVIEW_ERROR_TEXT: 'Błąd przy pobieraniu strony frontowej',
    T_API_HOSTNAME_IRI_ERROR_TITLE: 'Błąd',
    T_API_HOSTNAME_IRI_ERROR_TEXT: 'Błąd przy pobieraniu konfiguracji domeny',
    /* Thumbs */
    'T_THUMB_API/PAGE_BLOCK_THUMBS_CROPPED_EXAMPLE': 'Kadr przykładowy',
    /* Returned by API: modules */
    Administrators: 'Administratorzy',
    'Administrator Groups': 'Grupy administratorskie',
    'Registry entries': 'Rejestr zmian',
    'Login entries': 'Historia logowania',
    Pages: 'Struktura stron',
    'Page blocks': 'Bloki strony',
    'Page block thumbs': 'Kadry bloku strony',
    'Translator entries': 'Tłumaczenia',
    Articles: 'Artykuły',
    'Page block sections': 'Sekcje bloku strony',
    'Page block section thumbs': 'Kadry sekcji bloku strony',
    'File packages': 'Pliki',
    'File package items': 'Elementy pakietu plików',
    'Gallery packages': 'Galerie',
    'Gallery package items': 'Elementy galerii',
    'Gallery package item thumbs': 'Kadry elementu galerii',
    /* Returned by API: fields */
    'Name and surname': 'Imię i nazwisko',
    Group: 'Grupa',
    'Is enabled': 'Aktywny',
    Title: 'Tytuł',
    Username: 'Nazwa użytkownika',
    'Origin ip': 'IP',
    Result: 'Status',
    'Is super admin': 'Prawa super admina',
    'Is redactor': 'Prawa redaktora',
    'Title of article': 'Tytuł artykułu',
    'Text of article': 'Zawartość artykułu',
    'Show in main menu': 'Pokaż w menu głównym',
    Text: 'Tekst główny',
    'Meta title': '[SEO] Meta tytuł',
    'Meta description': '[SEO] Meta opis',
    'Meta keywords': '[SEO] Meta słowa kluczowe',
    Slug: 'Slug (generowany automatycznie z tytułu)',
    'Code of translator entry': 'Kod tłumaczenia',
    'Title of translator entry': 'Tłumaczenie',
    Question: 'Pytanie',
    Answer: 'Odpowiedź',
    Name: 'Nazwa',
    'Type of element': 'Typ elementu',
    'Is required': 'Wymagany do uzupełnienia',
    Description: 'Opis',
    Subtitle: 'Podtytuł',
    Quotation: 'Cytat',
    'Button title': 'Tytuł linka',
    'Button link': 'Link',
    Sections: 'Sekcje',
    'Text before media': 'Tekst przed zdjęciem',
    'Text after media': 'Tekst po zdjęciu',
    'Item type': 'Typ medium',
    'Media position': 'Pozycja zdjęcia',
    'Gallery type': 'Typ galerii',
    'Photo count': 'Zdjęć',
    'Video count': 'Filmów',
    'Media type': 'Typ medium',
    Photographer: 'Fotograf',
    'Code of entry': 'Kod',
    'Administration group': 'Grupa',
    /* Returned by API: general */
    'Your current password is incorrect.': 'Podałeś niepoprawne hasło',
    'Bad credentials.': 'Niepoprawne dane',
    Error: 'Błąd',
    TypeError: 'Błąd',
    'An error occurred': 'Błąd',
    'Not Found': 'Rekord nieodnaleziony',
    'Syntax error': 'Błąd składni. Skontaktuj się z administratorem',
    'This value is already used.': 'Ta wartość jest już używana',
    'This value should not be null.': 'Pole wymagane',
    Forbidden: 'Brak dostępu do tego zasobu',
    Unauthorized: 'Dostęp zablokowany',
    'Internal Server Error': 'Wystąpił błąd. Skontaktuj się z administratorem',
    'TypeError: Failed to fetch':
      'Błąd połączenia z serwerem. Skontaktuj się z administratorem',
    'Password is common.': 'Wybrałeś znane hasło',
    'You have already used that password.': 'Hasło zostało już wcześniej użyte',
    'Login blocked for 15 mins': 'Logowanie zablokowane na 15 minut',
    'Circular parentage detected. Current entity or current entity descendants cannot be set as a parent to current entity.':
      'Niepoprawna zmiana rodzica. Nie można przenieść strony w dół aktualnego drzewa.',
    'Parent page is not subpageable.':
      'Niepoprawna zmiana rodzica. Rodzic nie może posiadać podstron.',
    'Nesting level exceeded.':
      'Niepoprawna zmiana rodzica. Osiągnięto maksymalny poziom zagnieżdżenia stron.',
    'Youtube thumbnail does not exist.': 'Zaślepka nie istnieje',
    'Admin does not exist.': 'Administrator nie istnieje.',
    'Reset password link has been already generated.':
      'Link do zresetowania hasła został już wygenerowany.',
    'Incorrect hash.': 'Niepoprawny link.',
    'Link expired.': 'Wygasły link.',
    'Reset password blocked for 15 mins.':
      'Resetowanie hasła zablokowane na 15 minut',
    'Depth exceeded.': 'Głębokość przekroczona',
    'Gallery title': 'Tytuł galerii',
    'Files package title': 'Tytuł zestawu plików',
    Highlighted: 'Wyróżnienie',
    Address: 'Adres',
    'Additional offers': 'Dodatkowa oferta',
    Amenities: 'Udogodnienia',
    'Additional information': 'Dodatkowe informacje',
    Directions: 'Jak dojadę',
    Categories: 'Kategorie',
    Products: 'Produkty',
    T_CONFIG_PAGE_TITLE: 'Tytuł strony',
    T_CONFIG_CONTACT_ADDRESS: 'Adres w kontakcie',
    T_CONFIG_EMAIL_SEND_ADDRESS:
      'Domyślny adres email na który są wysyłane formularze z pakietów',
    T_CONFIG_GOOGLE_MAP_API_KEY: '[GOOGLE] mapy - klucz api',
    T_CONFIG_BIP_ENTITY_NAME: '[BIP] Podmiot udostępniający informację',
    T_MODULE_SECTIONS: 'Sekcje',
    T_MODULE_HOME_BANNER_NEW_BUTTON: 'Dodaj baner',
    T_MODULE_HOME_BANNER: 'Banery',
    T_MODULE_CUSTOMER: 'Klienci',
    T_MODULE_CUSTOMER_NEW_BUTTON: 'Dodaj klienta',
    T_MODULE_CUSTOMER_GROUP: 'Grupy',
    T_MODULE_CUSTOMER_GROUP_NEW_BUTTON: 'Dodaj grupę',
    T_MODULE_CUSTOMER_LOGIN_LOG: 'Historia logowania',
    T_MODULE_CUSTOMER_ORDERS: 'Zamówienia klienta',
    'Additional notes': 'Uwagi',
    'Admin can edit': 'Administrator może edytować',
    Year: 'Rok',
    Month: 'Miesiąc',
    January: 'Styczeń',
    February: 'Luty',
    March: 'Marzec',
    April: 'Kwiecień',
    May: 'Maj',
    June: 'Czerwiec',
    July: 'Lipiec',
    August: 'Sierpień',
    September: 'Wrzesień',
    October: 'Październik',
    November: 'Listopad',
    December: 'Grudzień',
    T_GENERAL_EMAIL: 'Email',
    T_MODULE_HOSTNAME_ENUM_0: 'PIASKOWNICA',
    T_MODULE_HOSTNAME_ENUM_1: 'STRONA_ZBIORCZA',
    T_MODULE_HOSTNAME_ENUM_2: 'Babiogórski',
    T_MODULE_HOSTNAME_ENUM_3: 'Białowieski',
    T_MODULE_HOSTNAME_ENUM_4: 'Biebrzański',
    T_MODULE_HOSTNAME_ENUM_5: 'Bieszczadzki',
    T_MODULE_HOSTNAME_ENUM_6: '„Bory Tucholskie”',
    T_MODULE_HOSTNAME_ENUM_7: 'Drawieński',
    T_MODULE_HOSTNAME_ENUM_8: 'Gorczański',
    T_MODULE_HOSTNAME_ENUM_9: 'Gór Stołowych',
    T_MODULE_HOSTNAME_ENUM_10: 'Kampinoski',
    T_MODULE_HOSTNAME_ENUM_11: 'Karkonoski',
    T_MODULE_HOSTNAME_ENUM_12: 'Magurski',
    T_MODULE_HOSTNAME_ENUM_13: 'Narwiański',
    T_MODULE_HOSTNAME_ENUM_14: 'Ojcowski',
    T_MODULE_HOSTNAME_ENUM_15: 'Pieniński',
    T_MODULE_HOSTNAME_ENUM_16: 'Poleski',
    T_MODULE_HOSTNAME_ENUM_17: 'Roztoczański',
    T_MODULE_HOSTNAME_ENUM_18: 'Słowiński',
    T_MODULE_HOSTNAME_ENUM_19: 'Świętokrzyski',
    T_MODULE_HOSTNAME_ENUM_20: 'Tatrzański',
    T_MODULE_HOSTNAME_ENUM_21: '„Ujście Warty”',
    T_MODULE_HOSTNAME_ENUM_22: 'Wielkopolski',
    T_MODULE_HOSTNAME_ENUM_23: 'Wigierski',
    T_MODULE_HOSTNAME_ENUM_24: 'Woliński',
    T_MODULE_FOOTER_ITEMS: 'Pozycje',
    T_MODULE_FOOTER: 'Stopka - kolumny',
    T_MODULE_FOOTER_NEW_BUTTON: 'Dodaj kolumnę',
    Footer: 'Stopka',
    T_ALERT_ICON_LABEL: 'Rodzaj ikony',
    T_ALERT_STYLE_LABEL: 'Styl alertu',
    T_ALERT_ICON_ENUM_1: 'zakazy i komunikaty krytyczne',
    T_ALERT_ICON_ENUM_2: 'ostrzeżenia',
    T_ALERT_ICON_ENUM_3: 'komunikaty o wydźwięku pozytywnym',
    T_ALERT_ICON_ENUM_4: 'informacje neutralne',
    T_ALERT_ICON_ENUM_5: 'informacje neutralne, mniej istotne',
    T_ALERT_STYLE_ENUM_1: 'Szare tło',
    T_ALERT_STYLE_ENUM_2: 'Ramka',
    T_BLOCK_STYLE_LABEL: 'Wersja bloku',
    T_BLOCK_TWO_COLUMNS_LABEL: 'Dwie kolumny',
    T_BLOCK_STYLE_ENUM_1: 'Jasna',
    T_BLOCK_STYLE_ENUM_2: 'Ciemna',
    T_BLOCK_SHOW_PICTOGRAMS_LABEL: 'Piktogramy',
    T_BLOCK_TRAILS_LEAD: 'Lead',
    T_BLOCK_TRAILS_TEXT1: 'Krótki opis',
    T_BLOCK_TRAILS_TEXT2: 'Krótki opis pliku',
    T_BLOCK_EXPANDABLE_LABEL: 'Rozwinięty',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_VIDEO: 'Blok tekstowy z filmem',
    T_GENERAL_BLOCK_TYPE_ANCHOR: 'Blok tekstowy z kotwicami',
    T_GENERAL_BLOCK_TYPE_ALERT: 'Blok z komunikatem',
    T_GENERAL_BLOCK_TYPE_BANNER1: 'Blok z 1 banerem',
    T_GENERAL_BLOCK_TYPE_BANNER1_VOLUNTEERING: 'Blok z 1 banerem (zgłoś się)',
    T_GENERAL_BLOCK_TYPE_BANNER2: 'Blok z 2 banerami',
    T_GENERAL_BLOCK_TYPE_NEWSLETTER: 'Blok z newsletterem',
    T_GENERAL_BLOCK_TYPE_PRODUCT: 'Blok z produktem',
    T_GENERAL_BLOCK_TYPE_LIST: 'Blok z listą',
    T_GENERAL_BLOCK_TYPE_TRAILS: 'Blok ze trasą',
    T_GENERAL_BLOCK_TYPE_TRAILS_FILES: 'Pliki',
    T_GENERAL_BLOCK_TYPE_TRAILS_EMBED: 'Kod embed mapy',
    T_GENERAL_BLOCK_TYPE_EMBED_TEXT: 'Kod embed',
    T_GENERAL_BLOCK_TYPE_LIST_TEXT: 'Odpowiedź',
    T_GENERAL_BLOCK_TYPE_TRAILS_COLUMN_TITLE1: 'Tytuł kolumny 1',
    T_GENERAL_BLOCK_TYPE_TRAILS_COLUMN_TITLE2: 'Tytuł kolumny 2',
    T_GENERAL_BLOCK_TYPE_TRAILS_COLUMN_HINT1: 'Tekst kolumny 1',
    T_GENERAL_BLOCK_TYPE_TRAILS_COLUMN_HINT2: 'Tekst kolumny 2',
    T_GENERAL_BLOCK_TYPE_ANCHORS: 'Kotwice',
    T_GENERAL_BLOCK_TYPE_TRAIL_CONDITIONS: 'Blok z warunkami na trasie',
    T_GENERAL_BLOCK_TYPE_TOURISM_INFORMATION_NOTICES:
      'Blok z komunikatem turystycznym',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO_FLOAT:
      'Blok tekstowy z pływającym zdjęciem',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO_ZOOM: 'Blok z klikalnym zdjęciem',
    T_GENERAL_BLOCK_TYPE_LOGOTYPES_WITH_CONTENT: 'Blok logotypy z treścią',
    T_GENERAL_BLOCK_TYPE_NEWS_CATEGORIES: 'Blok z aktualnościami',
    T_GENERAL_LINK: 'Link',
    T_GENERAL_LINK2: 'Link 2',
    T_GENERAL_LINK_TITLE: 'Tytuł linku',
    T_GENERAL_LINK_TITLE2: 'Tytuł linku 2',
    T_GENERAL_SHOW_MORE_BUTTON: 'Przycisk pokaż więcej',
    T_GENERAL_PICTOGRAM: 'Piktogram',
    T_GENERAL_ALT_PICTOGRAM: 'Znacznik alt piktogramu',
    T_GENERAL_PREDEFINED_ICON: 'Predefiniowana ikona',
    T_GENERAL_PREDEFINED_ICON_ALT: 'Znacznik alt ikony',
    T_GENERAL_ICON: 'Ikona',
    T_GENERAL_PARK: 'Park',
    T_MODULE_TRAIL: 'Trasy',
    T_MODULE_TRAIL_TITLE: 'Nazwa trasy',
    T_MODULE_TRAIL_NEW_BUTTON: 'Dodaj trasę',
    T_MODULE_TRAIL_CATEGORY: 'Kategorie',
    T_MODULE_TRAIL_CATEGORY_NEW_BUTTON: 'Dodaj kategorię',
    T_MODULE_TRAIL_CATEGORY_IS_WARNING: 'Ostrzeżenie',
    T_MODULE_TRAIL_REVIEW_COUNT: 'Recenzje',
    T_MODULE_TRAIL_REVIEW_NEW_BUTTON: 'Dodaj recenzję',
    T_MODULE_TRAIL_REVIEW_AUTHOR: 'Autor recenzji',
    T_MODULE_TRAIL_REVIEW_TEXT: 'Tekst recenzji',
    T_MODULE_TRAIL_SLOPE: 'Nachylenie średnie (w procentach)',
    T_MODULE_TRAIL_DISTANCE: 'Długość odcinka (w metrach)',
    T_MODULE_TRAIL_BEGINNING: 'Początek',
    T_MODULE_TRAIL_END: 'Koniec',
    T_MODULE_TRAIL_TIME_UP: 'Czas w górę (w minutach)',
    T_MODULE_TRAIL_TIME_UP_DESCRIPTION: 'Czas w górę - opis',
    T_MODULE_TRAIL_TIME_DOWN: 'Czas w dół (w minutach)',
    T_MODULE_TRAIL_TIME_DOWN_DESCRIPTION: 'Czas w doł - opis',
    T_MODULE_TRAIL_SURFACE: 'Nawierzchnia',
    T_MODULE_TRAIL_SUBCATEGORY: 'Podkategorie',
    T_MODULE_TRAIL_SUBCATEGORY_NEW_BUTTON: 'Dodaj podkategorie',
    T_MODULE_TRAIL_DESCRIPTION: 'Opis trasy',
    T_TRAIL_CATEGORY_ICON_ENUM_WHEELCHAIR: 'Dostępny dla wózków',
    T_TRAIL_CATEGORY_ICON_ENUM_SCENIC: 'Szlak krajobrazowy',
    T_TRAIL_CATEGORY_ICON_ENUM_NATURE: 'Ścieżka przyrodnicza',
    T_TRAIL_CATEGORY_ICON_ENUM_BEGINNERS: 'Dobry dla początkujących',
    T_TRAIL_CATEGORY_ICON_ENUM_CARRIAGE: 'Wózek dziecięcy',
    T_TRAIL_CATEGORY_ICON_ENUM_SKIING: 'Narciarz',
    T_TRAIL_CATEGORY_ICON_ENUM_HORSE: 'Koń',
    T_TRAIL_CATEGORY_ICON_ENUM_BICYCLE: 'Rower',
    T_TRAIL_INFORMATION_ICON_ENUM_ATTENTION: 'Uwaga',
    T_TRAIL_INFORMATION_ICON_ENUM_TEMPERATURE: 'Temperatura',
    T_TRAIL_INFORMATION_ICON_ENUM_TRACK: 'Trasa z nawierzchnią',
    T_TRAIL_INFORMATION_ICON_ENUM_SUNSET: 'Zachód słońca',
    T_TRAIL_INFORMATION_TITLE: 'Bezpieczeństwo na trasie',
    T_TRAIL_EMBED: 'Kod embed mapy',
    T_GENERAL_BLOCK_TYPE_LIST_ITEMS: 'Elementy',
    T_GENERAL_BLOCK_ANCHOR_ID: 'Nazwa kotwicy używana w bloku z kotwicami',
    T_GENERAL_BLOCK_BANNER1_VOLUNTEERING_FORM:
      'Automatyczny link do formularza',
    T_MODULE_PARK: 'Parki',
    T_MOBULE_PARK_GOOGLE_MAPS_URL: 'Link do mapy google',
    T_MOBULE_PARK_MAP_ZOOM: 'Zbliżenie mapy (np 1.5)',
    T_MOBULE_PARK_MAP_CORD_X: 'Koordynat mapy: X',
    T_MOBULE_PARK_MAP_CORD_Y: 'Koordynat mapy: Y',
    T_MOBULE_PARK_PHONE_NUMBER: 'Numer telefonu',
    T_MODULE_PARK_PAGE: 'Przydatne linki',
    T_MODULE_PARK_HOMEPAGE: 'Strona główna',
    T_MODULE_PARK_PAGE_SELECT: 'Wybierz stronę',
    T_MODULE_PARK_IMAGES: 'Zdjęcia',
    T_MENU_TOP_LEVEL: 'Menu - najwyższy poziom',
    T_MENU_CHILD: 'Menu - dziecko',
    // T_MENU_TOP_LEVEL: 'Menu - najwyższy poziom',
    // T_MENU_CHILD: 'Menu - dziecko',
    T_PARK_PAGE_ICON_ENUM_HOMEPAGE: 'Strona główna',
    T_PARK_PAGE_ICON_ENUM_TICKETS: 'Bilety',
    T_PARK_PAGE_ICON_ENUM_EDUCATION: 'Edukacja',
    T_PARK_PAGE_ICON_ENUM_SHOP: 'Sklep',
    T_PARK_PAGE_ICON_ENUM_VOLUNTEERING: 'Wolontariat',
    T_GENERAL_MOVIE_TITLE: 'Tytuł filmu',
    T_GENERAL_SECTION_TITLE: 'Tytuł sekcji',
    T_GENERAL_SUGGESTED: 'Sugerowane',
    T_MODULE_VOLUNTEERING: 'Wolontariat',
    T_MODULE_VOLUNTEERING_LIST: 'Akcje',
    T_MODULE_VOLUNTEERING_NEW_BUTTON: 'Dodaj akcję wolontariacką',
    T_MODULE_VOLUNTEERING_CATEGORY: 'Kategorie wolontariatu',
    T_MODULE_VOLUNTEERING_CATEGORY_NEW_BUTTON: 'Dodaj kategorię',
    T_MODULE_VOLUNTEERING_SUBMISSION_COUNT: 'Liczba zgłoszeń',
    T_MODULE_VOLUNTEERING_GUARDIAN: 'Opiekun',
    T_MODULE_VOLUNTEERING_GUARDIAN_EMAIL: 'Opiekun (email)',
    T_MODULE_VOLUNTEERING_GUARDIAN_EMAIL_HINT:
      'Email do powiadomień o nowym zgłoszeniu',
    T_MODULE_VOLUNTEERING_VACANCIES: 'Liczba miejsc',
    T_MODULE_VOLUNTEERING_FORM_DESCRIPTION: 'Opis formularza',
    T_MODULE_VOLUNTEERING_USER_LIST: 'Lista wolontariuszy',
    T_MODULE_VOLUNTEERING_USER_LIST_NEW_BUTTON: 'Dodaj wolontariusza',
    T_MODULE_VOLUNTEERING_SUBMISSION: 'Zgłoszenia',
    T_MODULE_VOLUNTEERING_ACCOMMODATION: 'Zakwaterowanie',
    T_MODULE_VOLUNTEERING_ACCOMMODATION_NEW_BUTTON: 'Dodaj zakwaterowanie',
    T_MODULE_VOLUNTEERING_LOCATION: 'Lokacje',
    T_MODULE_VOLUNTEERING_LOCATION_NEW_BUTTON: 'Dodaj lokacje',
    T_MODULE_VOLUNTEERING_TASK: 'Lista akcji',
    T_MODULE_VOLUNTEERING_LOCATION_SELECT: 'Lokacja',
    T_MODULE_VOLUNTEERING_TIMESHEET: 'Karty czasu pracy',
    T_MODULE_VOLUNTEERING_TIMESHEET_NEW_BUTTON: 'Dodaj kartę',
    T_MODULE_VOLUNTEERING_TIMESHEET_ACTION_SELECT: 'Akcja',
    T_MODULE_VOLUNTEERING_TIMESHEET_DATE_FROM: 'Data rozpoczęcia pracy',
    T_MODULE_VOLUNTEERING_TIMESHEET_DATE_TO: 'Data zakończenia pracy',
    T_MODULE_VOLUNTEERING_TIMESHEET_TOTAL_HOURS: 'Suma przepracowanych godzin',
    T_MODULE_VOLUNTEERING_TIMESHEET_DESCRIPTION: 'Opis pracy',
    T_MODULE_VOLUNTEERING_TIMESHEET_OPINION: 'Opinia o wykonanej pracy',
    T_MODULE_VOLUNTEERING_SUBMISSION_COLUMN_ACTION: 'Akcja',
    T_MODULE_VOLUNTEERING_SUBMISSION_COLUMN_ACTION_DATE_FROM:
      'Data rozpoczęcia akcji',
    T_MODULE_VOLUNTEERING_SUBMISSION_COLUMN_ACTION_DATE_TO:
      'Data zakończenia akcji',
    T_MODULE_VOLUNTEERING_EVENT: 'Wydarzenia',
    T_MODULE_VOLUNTEERING_EVENT_NEW_BUTTON: 'Dodaj wydarzenie',
    T_MODULE_VOLUNTEERING_EVENTS_LIST: 'Wydarzenia',
    T_VOLUNTEERING_STATUS_DISABLED: 'Wyłączona',
    T_VOLUNTEERING_STATUS_NEW: 'Nowa',
    T_VOLUNTEERING_STATUS_ONGOING: 'W trakcie',
    T_VOLUNTEERING_STATUS_FINISHED: 'Zakończona',
    T_VOLUNTEERING_ACCOMMODATION_TYPE_OWN: 'Własne',
    T_VOLUNTEERING_ACCOMMODATION_TYPE_TPN: 'TPN',
    T_MODULE_VOLUNTEER_USER_CONTRACT_NEW_BUTTON: 'Dodaj umowę',
    T_MODULE_VOLUNTEER_USER_CONTRACT: 'Umowy',
    T_MODULE_VOLUNTEER_USER_CONTRACT_LIST: 'Umowy',
    T_VOLUNTEER_ACCOUNT_STATUS_AWAITING: 'Oczekujący',
    T_VOLUNTEER_ACCOUNT_STATUS_ACCEPTED: 'Zaakceptowany',
    T_VOLUNTEER_ACCOUNT_STATUS_REJECTED: 'Odrzucony',
    T_VOLUNTEER_ACCOUNT_STATUS_BLOCKED: 'Zablokowany',
    T_STATUS_AWAITING: 'Oczekuje',
    T_STATUS_ACCEPTED: 'Zaakceptowany',
    T_STATUS_REJECTED: 'Odrzucony',
    T_MODULE_SHOP_PRODUCT: 'Produkty',
    T_MODULE_SHOP_PRODUCT_NEW_BUTTON: 'Dodaj produkt',
    T_MODULE_SHOP_PRODUCT_TITLE: 'Nazwa produktu',
    T_MODULE_SHOP_VARIANT: 'Warianty',
    T_MODULE_SHOP_CATEGORY: 'Kategorie',
    T_MODULE_SHOP_CATEGORY_NEW_BUTTON: 'Dodaj kategorię',
    T_MODULE_SHOP_CATEGORY_ADD_SUBCATEGORY: 'Dodaj podkategorię',
    T_MODULE_SHOP_VARIANT_NEW_BUTTON: 'Dodaj wariant',
    T_MODULE_SHOP_VARIANT_OPTIONS_NEW_BUTTON: 'Dodaj opcję',
    T_MODULE_SHOP_VARIANT_OPTIONS_HEADER_TITLE: 'Dostępne opcje',
    T_MODULE_SHOP_VARIANT_OPTIONS_EXPAND_TITLE: 'opcje',
    T_MODULE_SHOP_VARIANT_OPTION_TITLE: 'Opcja',
    T_MODULE_SHOP_PRODUCT_MAIN_VARIANT: 'Domyślny wariant',
    T_MODULE_SHOP_PRODUCT_SECONDARY_VARIANT: 'Drugorzędny wariant (opcjonalny)',
    T_MODULE_SHOP_PRODUCT_VARIANT_SECONDARY_VARIANT: 'Drugorzędny wariant',
    T_MODULE_SHOP_PRODUCT_VARIANT_NEW_BUTTON: 'Dodaj stan magazynowy',
    T_MODULE_SHOP_PRODUCT_VARIANTS_EMPTY:
      'Nie dodano żadnych stanów magazynowych!',
    T_MODULE_SHOP_PRODUCT_VARIANTS_HEADER_TITLE: 'Stany magazynowe',
    T_MODULE_SHOP_PRODUCT_VARIANT_OPTION_TITLE: 'stan',
    T_MODULE_SHOP_PRODUCT_VARIANT_IS_DIGITAL: 'Produkt cyfrowy',
    T_MODULE_SHOP_PRODUCT_INFORMATION_TITLE_HEADER: 'Parametry',
    T_MODULE_SHOP_PRODUCT_INFORMATION_ITEM_TITLE: 'Parametr',
    T_MODULE_SHOP_PRODUCT_INFORMATION_NEW_BUTTON: 'Dodaj parametr',
    T_MODULE_SHOP_PRODUCT_INFORMATION_HEADER: 'Lista parametrów',
    T_MODULE_SHOP_PRODUCT_MAIN_MEDIA: 'Główne zdjęcie',
    T_MODULE_SHOP_PRODUCT_MEDIA: 'Zdjęcie',
    T_MODULE_SHOP_PRODUCT_IMAGES_TITLE_HEADER: 'Zdjęcia',
    T_MODULE_SHOP_PRODUCT_IS_NEW: 'Nowość',
    T_MODULE_SHOP_PRODUCT_BESTSELLER: 'Bestseller',
    T_MODULE_SHOP_PRODUCT_SUGGESTED: 'Polecany',
    T_MODULE_SHOP_PRODUCT_SUBSCRIPTION: 'Prenumerata',
    T_MODULE_SHOP_PRODUCT_FILES_TITLE_HEADER: 'Pliki do pobrania',
    T_MODULE_SHOP_PRODUCT_FILE: 'Plik',
    T_MODULE_SHOP_PRODUCT_FILE_ALERT_INFO:
      'Pliki do których użytkownik uzyskuje dostęp w przypadku zakupu produktu cyfrowego',
    T_MODULE_SHOP_PRODUCT_REVIEWS_HEADER_TITLE: 'Opinie',
    T_MODULE_SHOP_REVIEW_OPTIONS_EXPAND_TITLE: 'Opinie',
    T_MODULE_SHOP_PRODUCT_REVIEW_OPTION_TITLE: 'Opinia',
    T_MODULE_SHOP_PRODUCT_REVIEW_NEW_BUTTON: 'Dodaj recenzję',
    T_MODULE_SHOP_PRODUCT_VARIANTS_INFORMATION:
      'W przypadku zmiany lub dodaniu wariantu, należy pamiętać o aktualizacji stanów magazynowych!',
    T_MODULE_SHOP_PRODUCT_CATEGORY: 'Kategoria',
    T_MODULE_SHOP_SHIPPING_TYPE: 'Metody dostawy',
    T_MODULE_SHOP_SHIPPING_TYPE_NEW_BUTTON: 'Dodaj metodę',
    T_MODULE_SHOP_SHIPPING_TYPE_OPERATOR: 'Operator dostawy',
    T_MODULE_SHOP_SHIPPING_TYPE_PAYMENT_TYPES: 'Metody płatności',
    T_MODULE_SHOP_SHIPPING_POINT: 'Punkty odbioru',
    T_MODULE_SHOP_SHIPPING_POINT_NEW_BUTTON: 'Dodaj punkt odbioru',
    T_MODULE_SHOP_PAYMENT_METHOD: 'Metody płatności',
    T_MODULE_SHOP_PAYMENT_METHOD_NEW_BUTTON: 'Dodaj metodę',
    T_MODULE_SHOP_PAYMENT_METHOD_OPERATOR: 'Operator płatności',
    T_MODULE_SHOP_DISCOUNT_CODE: 'Kody promocyjne',
    T_MODULE_SHOP_DISCOUNT_CODE_NEW_BUTTON: 'Dodaj kod',
    T_MODULE_SHOP_DISCOUNT_CODE_CODE: 'Kod',
    T_MODULE_SHOP_DISCOUNT_CODE_RATE: 'Zniżka (%)',
    T_MODULE_SHOP_DISCOUNT_CODE_MINIMAL_PRICE: 'Minimalna kwota',
    T_MODULE_SHOP_DISCOUNT_CODE_START_DATE: 'Data rozpoczęcia',
    T_MODULE_SHOP_DISCOUNT_CODE_END_DATE: 'Data zakończenia',
    T_MODULE_SHOP_DISCOUNT_CODE_TIMES_USED: 'Użyto',
    T_MODULE_SHOP_PROMOTION: 'Promocje',
    T_MODULE_SHOP_PROMOTION_NEW_BUTTON: 'Dodaj promocję',
    T_MODULE_SHOP_PROMOTION_FREE_SHIPPING: 'Darmowa dostawa',
    T_MODULE_SHOP_SHIPPING_AREA: 'Obszary',
    T_MODULE_SHOP_SHIPPING_AREA_NEW_BUTTON: 'Dodaj obszar',
    T_MODULE_SHOP_SHIPPING_AREA_COUNTRIES: 'Kraje',
    T_MODULE_SHOP_SHIPPING_AREA_COST: 'Koszty przesyłki',
    T_MODULE_SHOP_SHIPPING_AREA_COST_NEW_BUTTON: 'Dodaj',
    T_MODULE_SHOP_SHIPPING_AREA_COST_PRICE: 'Cena',
    T_MODULE_SHOP_SHIPPING_AREA_COST_SHIPPING_TYPE: 'Typ wysyłki',
    T_MODULE_SHOP_SHIPPING_COUNTRY: 'Kraje',
    T_MODULE_SHOP_SHIPPING_COUNTRY_NEW_BUTTON: 'Dodaj kraj',
    T_MODULE_SHOP_SHIPPING_COUNTRY_AREA: 'Obszar',
    T_MODULE_SHOP_SHIPPING_COUNTRY_CODE: 'Kod kraju (ISO, dwie litery)',
    T_MODULE_SHOP_ORDER: 'Zamówienia',
    T_MODULE_SHOP_ORDER_NEW_BUTTON: 'Dodaj zamówienie',
    T_MODULE_SHOP_PRODUCT_SUBSCRIPTION_FROM: 'Od numeru:',
    T_MODULE_SHOP_SUBSCRIPTION_SENDER: 'Wysyłka',
    T_MODULE_SHOP_SUBSCRIPTION: 'Prenumerata',
    T_MODULE_SHOP_SUBSCRIPTION_NEW_BUTTON: 'Dodaj',
    T_MODULE_SHOP_SUBSCRIPTION_LEGACY_STATUS: 'Status(stary sklep)',
    T_MODULE_SHOP_SUBSCRIPTION_STATUS: 'Status',
    T_MODULE_SHOP_SUBSCRIPTION_FROM: 'Od numeru',
    T_MODULE_SHOP_SUBSCRIPTION_TO: 'Do numeru',
    T_MODULE_SHOP_REPORT: 'Raporty',
    T_MODULE_BIP_PAGE: 'BIP - struktura stron',
    T_MODULE_BIP_PAGE_ARTICLE: 'BIP - artykuły',
    T_MODULE_BIP_PAGE_MENU_CATEGORY: 'Kategoria menu',
    T_MODULE_BIP_MENU_PAGE_CATEGORY: 'Kategorie menu',
    T_MODULE_BIP_MENU_PAGE_CATEGORY_NEW_BUTTON: 'Dodaj kategorię',
    T_MODULE_BIP_PAGE_ARTICLE_NEW_BUTTON: 'Dodaj artykuł',
    T_MODULE_BIP_PAGE_ALERT_NO_HOMEPAGE: 'Brak strony głównej!',
    T_MODULE_BIP_PAGE_VERSION_PUBLISHED_BADGE: 'Opublikowany',
    T_MODULE_BIP_PAGE_COLLECTION_EDIT_ARTICLE_LINK: 'Edytuj artykuł',
    T_MODULE_BIP_PAGE_COLLECTION_EDIT_PAGE_LINK: 'Edytuj stronę',
    T_MODULE_BIP_PAGE_ALERT_UNPUBLISHED_CHANGES:
      'Artykuł posiada nieopublikowane zmiany',
    T_MODULE_BIP_PAGE_VERSION: 'Wersje',
    T_MODULE_BIP_PAGE_ARTICLE_VERSION: 'Wersje artykułu',
    T_MODULE_BIP_PAGE_ARTICLE_COLLECTION_EDIT_LINK: 'Edytuj artykuł',
    T_MODULE_BIP_ENTITY: 'Podmioty (mapa)',
    T_MODULE_BIP_ENTITY_NEW_BUTTON: 'Dodaj podmiot',
    T_SHOP_PAYMENT_OPERATOR_WIRE_TRANSFER: 'Przelew tradycyjny',
    T_SHOP_PAYMENT_OPERATOR_PAYU: 'PayU',
    T_SHOP_PAYMENT_OPERATOR_PRZELEWY24: 'Przelewy24',
    T_SHOP_PAYMENT_OPERATOR_ZA_POBRANIEM: 'Za pobraniem',
    T_SHOP_SHIPPING_OPERATOR_IN_PERSON: 'Odbiór osobisty',
    T_SHOP_SHIPPING_OPERATOR_POCZTA_POLSKA: 'Poczta polska',
    T_SHOP_SHIPPING_OPERATOR_POCZTA_POLSKA_PICKUP_POINT:
      'Poczta polska - paczkomaty',
    T_SHOP_SHIPPING_OPERATOR_INPOST_KURIER: 'Inpost - kurier',
    T_SHOP_SHIPPING_OPERATOR_INPOST_PACZKOMATY: 'Inpost - paczkomaty',
    T_SHOP_SHIPPING_OPERATOR_ELECTRONIC: 'Elektroniczna',
    T_SHOP_SHIPPING_OPERATOR_IN_PERSON_ALERT: 'Nie uzupełniono punktu odbioru!',
    T_SHOP_SUBSCRIPTION_SENDER_TPN: 'TPN',
    T_SHOP_SUBSCRIPTION_SENDER_PRINTING_HOUSE: 'DRUKARNIA',
    T_ELEKTRONICZNY_NADAWCA_PACKAGE_TYPE_PRZESYLKA_POLECONA_KRAJOWA:
      'Przesyłka polecona krajowa',
    T_ELEKTRONICZNY_NADAWCA_PACKAGE_TYPE_PACZKA_POCZTOWA: 'Paczka pocztowa',
    T_ELEKTRONICZNY_NADAWCA_PACKAGE_TYPE_POCZTEX20: 'Pocztex 2.0',
    T_ELEKTRONICZNY_NADAWCA_PACKAGE_TYPE_POCZTEX_PICKUP_POINT:
      'Pocztex - punkt odbioru',
    T_ELEKTRONICZNY_NADAWCA_PACKAGE_TYPE_PACZKA_ZAGRANICZNA:
      'Paczka zagraniczna',
    T_ELEKTRONICZNY_NADAWCA_GENERATE_XLS: 'Generuj XLS (elektroniczny nadawca)',
    T_ELEKTRONICZNY_NADAWCA_GENERATE_XLS_SUCCESS: 'Wygenerowano plik XLS',
    T_GENERAL_ORDER_LABEL_REMOVED: 'Usunięto etykietę',
    T_MODULE_TRAIL_SUGGESTED_CATEGORY: 'Sugerowane kategorie',
    T_TOURISM_INFORMATION_NOTICE_TYPE_ALARM: 'Alarm',
    T_TOURISM_INFORMATION_NOTICE_TYPE_WARNING: 'Ostrzeżenie',
    T_TOURISM_INFORMATION_NOTICE_TYPE_INFORMATION: 'Informacja',
    T_TOURISM_INFORMATION_NOTICE_TYPE_POSITIVE: 'Pozytywna informacja',
    T_MODULE_TOURISM_NOTICE: 'Komunikat turystyczny',
    T_MODULE_TOURISM_NOTICE_NEW_BUTTON: 'Dodaj komunikat',
    T_MODULE_TOURISM_NOTICE_TYPE: 'Typ komunikatu',
    'Is warning': 'Ostrzeżenie',
    'Visible in PPN': 'Widoczny w PPN',

    T_MODULE_CREW_NEW_BUTTON: 'Dodaj pracownika',
    T_MODULE_CREW: 'Pracownicy',
    'T_THUMB_API/PAGE_THUMBS_DETAIL': 'kadr strona',
    'T_THUMB_API/MAIN_MENU_THUMBS_THUMB': 'kadr w menu',
    T_THUMB_API_MEDIA_SLIM: 'kadr podłużny',
    T_THUMB_API_MEDIA_ATTRACTION: 'kadr kwadratowy',

    T_MODULE_CONTEST_NEW_BUTTON: 'Dodaj konkurs',
    T_MODULE_CONTEST: 'Konkursy',
    T_THUMB_API_MEDIA_FINALIST: 'kadr listy',
    'Additional head code': 'Dodatkowy kod sekcji HTML HEAD',
    'Additional body code': 'Dodatkowy kod sekcji HTML BODY',
    T_SHOP_CONFIG_FREE_SHIPPING_THRESHOLD: '[SKLEP] - darmowa wysyłka od kwoty',
    T_SHOP_CONFIG_ELEKTRONICZNY_NADAWCA_LOGIN:
      '[SKLEP] - elektroniczny nadawca - login',
    T_SHOP_CONFIG_ELEKTRONICZNY_NADAWCA_PASSWORD:
      '[SKLEP] - elektroniczny nadawca - hasło',
    'Global limit': 'Globalny limit użyć',
    'Is poland': 'Polska',
    T_SHOP_MODULE_SUBSCRIPTION_SENDER: 'Wysyłka',
    T_SHOP_MODULE_SUBSCRIPTION_FROM: 'Od numeru',
    T_SHOP_MODULE_SUBSCRIPTION_TO: 'Do numeru',
    T_SHOP_MODULE_SUBSCRIPTION_TOTAL: 'Egz.',
    T_SHOP_MODULE_SUBSCRIPTION_STATUS: 'Status',
    T_SHOP_MODULE_ORDER_STATUS_WORKING: 'Robocze',
    T_SHOP_MODULE_ORDER_STATUS_NEW: 'Nowe',
    T_SHOP_MODULE_ORDER_STATUS_WAITING_FOR_PAYMENT: 'Oczekiwanie na płatność',
    T_SHOP_MODULE_ORDER_STATUS_CANCELED: 'Anulowane',
    T_SHOP_MODULE_ORDER_STATUS_PAYMENT_RECEIVED: 'Opłacone',
    T_SHOP_MODULE_ORDER_STATUS_IN_PREPARATION: 'W przygotowaniu',
    T_SHOP_MODULE_ORDER_STATUS_SUBSCRIPTION_IN_PREPARATION:
      'Przekazane do realizacji',
    T_SHOP_MODULE_ORDER_STATUS_WAITING_FOR_SHIPPING: 'Oczekuje na kuriera',
    T_SHOP_MODULE_ORDER_STATUS_IN_TRANSIT: 'Odebrana przez kuriera',
    T_SHOP_MODULE_ORDER_STATUS_SENT: 'Zamówienie wysłane',
    T_SHOP_MODULE_ORDER_STATUS_SUBSCRIPTION_IN_TRANSIT: 'Wysłane',
    T_SHOP_MODULE_ORDER_STATUS_DELIVERED: 'Dostarczono',
    T_SHOP_MODULE_ORDER_STATUS_RETURN_IN_PROGRESS: 'W trakcie zwrotu',
    T_SHOP_MODULE_ORDER_STATUS_RETURNED: 'Zwrócona',
    T_SHOP_MODULE_ORDER_STATUS_WAiTING_FOR_PICKUP: 'Oczekuje na odbiór',
    T_SHOP_MODULE_ORDER_STATUS_CONFIRMED: 'Potwierdzona',
    T_SHOP_MODULE_ORDER_STATUS_SUBSCRIPTION_CONFIRMED:
      'Prenumerata zatwierdzona',
    T_SHOP_MODULE_ORDER_STATUS_UNKNOWN: 'NIEZNANY',
    T_SHOP_MODULE_ORDER_STATUS_REALIZED: 'Zrealizowane',
    T_SHOP_MODULE_ORDER_STATUS_REALIZATION_01: 'Realizowane 01',
    T_SHOP_MODULE_ORDER_STATUS_REALIZATION_02: 'Realizowane 02',
    T_SHOP_MODULE_ORDER_STATUS_PACKAGE_RETURN: 'Zwrot paczki',
    T_SHOP_MODULE_ORDER_STATUS_INCOMPLETE_ADDRESS: 'Niepełny adres',
    T_SHOP_MODULE_ORDER_STATUS_CASH_ON_DELIVERY: 'Pobranie pocztowe',
    T_SHOP_MODULE_ORDER_STATUS_AWAITING_SURCHARGE: 'Oczekuje na dopłatę',
    T_SHOP_MODULE_ORDER_STATUS_IN_PREPARATION_1: 'W przygotowaniu - 1',
    T_SHOP_MODULE_ORDER_STATUS_IN_PREPARATION_2: 'W przygotowaniu - 2',
    T_SHOP_MODULE_ORDER_STATUS_IN_PREPARATION_3: 'W przygotowaniu - 3',
    T_SHOP_MODULE_ORDER_STATUS_PAYMENT_RECEIVED_PRZELEWY_24:
      'Opłacone przelewy24',
    T_SHOP_MODULE_ORDER_STATUS_DELAYED_SHIPPING:
      'Wysyłka w późniejszym terminie',
    T_SHOP_MODULE_ORDER_STATUS_RETURN_RECEIVED: 'Zwrot otrzymany',
    T_SHOP_MODULE_ORDER_STATUS_PACKAGE_REJECTED:
      'Zwrot - zamówienie nieodebrane',
    T_SHOP_MODULE_ORDER_VARIANT_TITLE: 'Produkt',
    T_SHOP_MODULE_ORDER_VARIANT_TITLE_HEADER: 'Produkty',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_ANULOWANE: 'Anulowane',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_BLEDNE: 'Błędne',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_ODWOLANE: 'Odwołane',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_PRENUMERATA_ZATWIERDZONA:
      'Prenumerata zatwierdzona',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_PRZEKAZANE_DO_REALIZACJI:
      'Przekazane do realizacji',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_PLATNOSC_PRZEZ_DOTPAY:
      'Płatność przez Dotpay',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_PLATNOSC_PRZEZ_DOTPAY_OCZEKUJE:
      'Płatność przez Dotpay - oczekuje',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_REALIZOWANE_01: 'Realizowane 01',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_REALIZOWANE_02: 'Realizowane 02',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_ROZPOCZETE_DOTPAY: 'Rozpoczęte - DOTPAY',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_W_REALIZACJI: 'W realizacji',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_WYGASLE_PRZETERMINOWANE:
      'Wygasłe (Przeterminowane)',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_WYSLANE: 'Wysłane',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_ZAMOWIENIE_WYSLANE: 'Zamówienie wysłane',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_ZREALIZOWANE: 'Zrealizowane',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_ZWROT_PACZKI: 'Zwrot paczki',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_NIEPELNY_ADRES: 'niepełny adres',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_POBRANIE_POCZTOWE: 'pobranie pocztowe',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_S03: 's03',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_S04: 's04',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_S05: 's05',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_S06: 's06',
    T_SHOP_MODULE_LEGACY_ORDER_STATUS_S07: 's07',
    T_SHOP_MODULE_FILTER_TYPE_PROMOTION_PAYMENT_RECEIVED: 'Opłacone',
    T_SHOP_MODULE_FILTER_TYPE_PROMOTION: 'Promocje',
    T_SHOP_MODULE_FILTER_TYPE_NEW: 'Nowości',
    T_SHOP_MODULE_FILTER_TYPE_BESTSELLER: 'Bestsellery',
    T_SHOP_MODULE_FILTER_TYPE_SUBSCRIPTION: 'Prenumerata',
    T_SHOP_MODULE_FILTER_TYPE_SUGGESTED: 'Polecane',

    T_ACCOMM_PDF_10: 'Pogoda',
    T_ACCOMM_PDF_40: 'Warunki',
    'Accommodation notices / powiadomienia / mailing': 'Powiadomienia',

    'Select shop category': 'Kategoria',
    Count: 'Ilość',
    T_MODULE_ACCOMM: 'Noclegi',
    T_MODULE_ACCOMM_NEW_BUTTON: 'Dodaj nocleg',
    T_MODULE_TRAIL_REVIEW: 'Recenzje',
    T_EDUCATION_PRIMARY_EDUCATION: 'podstawowe',
    T_EDUCATION_LOWER_SECONDARY_EDUCATION: 'gimnazjalne',
    T_EDUCATION_BASIC_VOCATIONAL_EDUCATION: 'zasadnicze zawodowe',
    T_EDUCATION_VOCATIONAL_BASIC_EDUCATION: 'zasadnicze branżowe',
    T_EDUCATION_VOCATIONAL_SECONDARY_EDUCATION: 'średnie branżowe',
    T_EDUCATION_SECONDARY_EDUCATION: 'średnie',
    T_EDUCATION_HIGHER_EDUCATION: 'wyższe',
    T_WORK_CONTRACT_TYPE_EMPLOYMENT_CONTRACT: 'umowa',
    T_WORK_CONTRACT_TYPE_CONTRACT_FOR_SERVICES: 'zlecenie',
    T_WORK_CONTRACT_TYPE_CONTRACT_FOR_A_SPECIFIC_TASK: 'umowa o dzieło',
    T_WORK_CONTRACT_TYPE_SELF_EMPLOYED: 'samozatrudnienie',
    T_EMPLOYMENT_FTE_QUARTER: '1/4 etetu',
    T_EMPLOYMENT_FTE_HALF: 'pół etatu',
    T_EMPLOYMENT_FTE_FULL: 'pełny etat',
    T_PROVINCE_DOLNOSLASKIE: 'Dolnośląskie',
    T_PROVINCE_KUJAWSKO_POMORSKIE: 'Kujawsko-Pomorskie',
    T_PROVINCE_LUBELSKIE: 'Lubelskie',
    T_PROVINCE_LUBUSKIE: 'Lubuskie',
    T_PROVINCE_LODZKIE: 'Łódzkie',
    T_PROVINCE_MALOPOLSKIE: 'Małopolskie',
    T_PROVINCE_MAZOWIECKIE: 'Mazowieckie',
    T_PROVINCE_OPOLSKIE: 'Opolskie',
    T_PROVINCE_PODKARPACKIE: 'Podkarpackie',
    T_PROVINCE_PODLASKIE: 'Podlaskie',
    T_PROVINCE_POMORSKIE: 'Pomorskie',
    T_PROVINCE_SLASKIE: 'Śląskie',
    T_PROVINCE_SWIETOKRZYSKIE: 'Świętokrzyskie',
    T_PROVINCE_WARMINSKO_MAZURSKIE: 'Warmińsko-Mazurskie',
    T_PROVINCE_WIELKOPOLSKIE: 'Wielkopolskie',
    T_PROVINCE_ZACHODNIOPOMORSKIE: 'Zachodniopomorskie',
    T_BLOCK_SHOP_BANNER_PROMOTION: 'Promocje',
    T_MODULE_GUIDE: 'Przewodnicy',
    T_MODULE_GUIDE_NEW_BUTTON: 'Dodaj przewodnika',
    T_GENERAL_GUIDE_NAME: 'Imię',
    Surname: 'Nazwisko',
    'Address: street': 'Adres: ulica',
    'Address: postal': 'Adres: kod pocztowy',
    'Address: city': 'Adres: miasto',
    'Is guide': 'Przewodnik',
    'Is instructor': 'Instruktor',
    'Is TPN employee': 'Pracownik TPN',
    Phone: 'Telefon',
    T_RESOURCE_GUIDE: 'Przewodnicy',
    Guides: 'Przewodnicy',
    'Full name': 'Imię i nazwisko',
    Type: 'Typ',
    Amount: 'Kwota',
    'Paid at': 'Data',
    T_MODULE_GUIDE_FEE: 'Opłaty',
    T_MODULE_GUIDE_FEE_NEW_BUTTON: 'Dodaj opłatę',
    T_MODULE_GUIDE_PERMISSION: 'Uprawnienia',
    T_MODULE_GUIDE_PERMISSION_NEW_BUTTON: 'Dodaj uprawnienia',
    'Guide fees': 'Opłaty',
    'Guide permissions': 'Uprawnienia',
    'Guide licences': 'Licencje',
    T_MODULE_GUIDE_LICENCE: 'Licencje',
    T_MODULE_GUIDE_LICENCE_NEW_BUTTON: 'Dodaj licencję',
    Number: 'Numer',
    'Issued at': 'Data',
    T_MODULE_SEA_EYE_FIACRE: 'Morskie Oko: fiakrzy',
    T_MODULE_SEA_EYE_FIACRE_NEW_BUTTON: 'Dodaj fiakra',
    'Sea eye: fiacres': 'Morskie Oko: fiakrzy',
    'Licence number': 'Numer licencji',
    'Registered at': 'Data rejestracji',
    'Withdrawn at': 'Data wycofania',
    T_MODULE_CHURCHISCO_FIACRE: 'Kościelisko: fiakrzy',
    T_MODULE_CHURCHISCO_FIACRE_NEW_BUTTON: 'Dodaj fiakra',
    'Churchisco: fiacres': 'Kościelisko: fiakrzy',
    T_MODULE_SEA_EYE_HORSE: 'Morskie Oko: konie',
    T_MODULE_SEA_EYE_HORSE_NEW_BUTTON: 'Dodaj konia',
    'Sea eye: horses': 'Morskie Oko: konie',
    'Chip number': 'Numer chipa',
    'Passport number': 'Numer paszportu',
    T_MODULE_CHURCHISCO_INSPECTION: 'Kościelisko: kontrole',
    T_MODULE_CHURCHISCO_INSPECTION_NEW_BUTTON: 'Dodaj kontrolę',
    'Churchisco: inspections': 'Kościelisko: kontrole',
    'Churchisco: inspection entries': 'Kościelisko: kontrolowani',
    Place: 'Miejsce',
    Inspector: 'Kontrolujący',
    'Inspected from': 'Od',
    'Inspected to': 'Do',
    Comments: 'Uwagi',
    T_MODULE_CHURCHISCO_INSPECTION_ENTRIES: 'Kontrolowani',
    T_RESOURCE_CHURCHISCO_SEA_EYE: 'Morskie Oko: fiakrzy',
    T_RESOURCE_CHURCHISCO_FIACRE: 'Kościelisko: fiakrzy',
    'Number of people at wagon': 'Ilość osób na wozie',
    Inspected: 'Kontrolowany',
    'In place of': 'W zastępstwie za',
    T_MODULE_CHURCHISCO_HISTORY_ENTRY: 'Kościelisko: historia',
    T_MODULE_CHURCHISCO_HISTORY_ENTRY_NEW_BUTTON: 'Dodaj wpis',
    'Churchisco: history entries': 'Kościelisko: historia',
    'Occured at': 'Data zdarzenia',
    Fiacre: 'Fiakr',
    Fiacres: 'Fiakrzy',
    T_MODULE_SEA_EYE_INSPECTION: 'Morskie Oko: kontrole',
    T_MODULE_SEA_EYE_INSPECTION_NEW_BUTTON: 'Dodaj kontrolę',
    'Sea eye: inspections': 'Morskie Oko: kontrole',
    T_MODULE_SEA_EYE_INSPECTION_ENTRIES: 'Kontrolowani',
    T_MODULE_SEA_EYE_HISTORY_ENTRY: 'Morskie Oko: historia',
    T_MODULE_SEA_EYE_HISTORY_ENTRY_NEW_BUTTON: 'Dodaj wpis',
    'Sea eye: history entries': 'Morskie Oko: historia',
    Horses: 'Konie',
    Horse: 'Koń',
    'Last examined at': 'Ostatnie badanie',
    Owners: 'Właściciele',
    'Is licence expired': 'Wygaśnięcie licencji',
    'Horses passports numbers': 'Paszport',
    T_MODULE_LESSON_HOST: 'Prowadzący',
    T_MODULE_LESSON_HOST_NEW_BUTTON: 'Dodaj prowadzącego',
    T_MODULE_LESSON_DISTRICT: 'Powiaty',
    T_MODULE_LESSON_DISTRICT_NEW_BUTTON: 'Dodaj powiat',
    T_MODULE_LESSON_COMMUNE: 'Gminy',
    T_MODULE_LESSON_COMMUNE_NEW_BUTTON: 'Dodaj gminę',
    T_MODULE_LESSON: 'Lista zajęć',
    T_MODULE_LESSON_NEW_BUTTON: 'Dodaj zajęcia',
    T_MODULE_LESSON_SCHEDULE: 'Terminy',
    T_MODULE_LESSON_SCHEDULE_NEW_BUTTON: 'Dodaj termin',
    T_MODULE_LESSON_SUBMISSION: 'Zapisy',
    T_MODULE_LESSON_MY_SUBMISSION: 'Moje zapisy',
    T_LESSON_CLASS_KINDERGARTEN: 'Przedszkole',
    T_LESSON_CLASS_KINDERGARTEN_0: 'Zerówka',
    T_LESSON_CLASS_PRIMARY_1: 'Klasa I',
    T_LESSON_CLASS_PRIMARY_2: 'Klasa II',
    T_LESSON_CLASS_PRIMARY_3: 'Klasa III',
    T_LESSON_CLASS_PRIMARY_4: 'Klasa IV',
    T_LESSON_CLASS_PRIMARY_5: 'Klasa V',
    T_LESSON_CLASS_PRIMARY_6: 'Klasa VI',
    T_LESSON_CLASS_PRIMARY_7: 'Klasa VII',
    T_LESSON_CLASS_PRIMARY_8: 'Klasa VII',
    T_LESSON_CLASS_HIGH_SCHOOL: 'Szkoła średnia',
    T_LESSON_CLASS_COLLEGE: 'Studia',
    T_LESSON_SCHEDULE_STATUS_TAKEN: 'Zarezerwowane',
    T_LESSON_SCHEDULE_STATUS_POSSIBLE: 'Zapis możliwy',
    T_LESSON_SCHEDULE_STATUS_IMPOSSIBLE: 'Zapis niemożliwy',
    'Fiacre or horse is required.':
      'Fiakr lub koń jest wymagany do uzupełnienia',
    'Fiacre, horse and new owner is required.':
      'Fiakr, koń i nowy własciciel są wymagani do uzupełnienia',
    Files: 'Pliki',
    T_MODULE_MATTER_FORM_CONFIG_GROUP: 'Konfiguracja',
    T_MODULE_MATTER_FORM_CONFIG_GROUP_NEW_BUTTON: 'Dodaj',
    T_MATTER_FORM_CONFIG_GROUP_TYPE_CAR: 'Wjazd na teren TPN',
    T_MATTER_FORM_CONFIG_GROUP_TYPE_MOVE: 'Poruszanie się po obszarze TPN',
    T_MATTER_FORM_CONFIG_GROUP_TYPE_SESSION_WEDDING:
      'Fotografowanie uroczystości rodzinnych np. sesje ślubne',
    T_MATTER_FORM_CONFIG_GROUP_TYPE_WEDDING: 'Ceremonia ślubna na terenie TPN',
    T_MATTER_FORM_CONFIG_GROUP_TYPE_CAR_DW:
      'Wjazd dla osób niepełnosprawnych Palenica Białczańska - Włosienica',
    T_MATTER_FORM_CONFIG_GROUP_TYPE_CAR_DMO:
      'Wjazd dla osób niepełnosprawnych Palenica Białczańska - Morskie Oko',
    T_MATTER_FORM_CONFIG_GROUP_TYPE_PHOTO: 'Wykonywanie zdjęć fotograficznych',
    T_MATTER_FORM_CONFIG_GROUP_TYPE_MOVIE: 'Filmowanie na terenie TPN',
    T_MATTER_FORM_CONFIG_GROUP_TYPE_SPORT: 'Organizacja zawodów sportowych',
    T_MATTER_FORM_CONFIG_GROUP_TYPE_SPORT2: 'Organizacja zawodów sportowych 2',
    T_MATTER_FORM_STATUS_REJECTED: 'Odrzucono',
    T_MATTER_FORM_STATUS_ACCEPTED: 'Zaakceptowano',
    T_MATTER_FORM_STATUS_UNKNOWN: 'Nieznany',
    T_MATTER_FORM_STATUS_CONFIRMED: 'Potwierdzony',
    T_MATTER_FORM_STATUS_UNCONFIRMED: 'Niepotwierdzony',
    T_MATTER_FORM_STATUS_RETURNED: 'Zwrócony',
    T_MODULE_MATTER_FORM_PERMISSION: 'Zezwolenia',
    T_MODULE_MATTER_FORM_REQUEST_NEW_BUTTON: 'Dodaj',
    T_MATTER_FORM_RUN_FORMAT_1:
      'Bieg górski typu vertical - na dystansie 5-10 km, różnica wzniesień (900 m+)',
    T_MATTER_FORM_RUN_FORMAT_2:
      'Bieg górski na dystansie 10,1-25 km, różnica wzniesień (1000 m+)',
    T_MATTER_FORM_RUN_FORMAT_3:
      'Bieg górski na dystansie 25,1-35 km, różnica wzniesień (2000 m+)',
    T_MATTER_FORM_RUN_FORMAT_4:
      'Bieg górski na dystansie 35,1-50 km, różnica wzniesień (3000 m+)',
    T_MATTER_FORM_RUN_FORMAT_5:
      'Bieg górski typu ultra na dystansie +50,1 km, różnica wzniesień (4000 m+)',
    T_MATTER_FORM_RUN_FORMAT_6:
      'Zawody skiturowe/zawody w narciarstwie wysokogórskim',
    T_MATTER_FORM_RUN_PERIODICITY_OPTION1: 'impreza odbywająca się co rok',
    T_MATTER_FORM_RUN_PERIODICITY_OPTION2: 'impreza odbywająca się co 2 lata',
    T_MATTER_FORM_RUN_PERIODICITY_OTHER: 'inne',
    T_MATTER_FORM_RUN_EDITION_OPTION1: 'impreza odbywa się po raz pierwszy',
    T_MATTER_FORM_RUN_EDITION_OPTION2: 'impreza odbywa się po raz kolejny',
    T_MATTER_FORM_RUN_EDITION_OPTION3: 'impreza odbywa się powyżej 10 lat',
    T_MATTER_FORM_RUN_CHAMPIONSHIP_PUCHAR_POLSKI: 'Puchar Polski',
    T_MATTER_FORM_RUN_CHAMPIONSHIP_MISTRZOSTWA_POLSKI: 'Mistrzostwa Polski',
    T_MATTER_FORM_RUN_CHAMPIONSHIP_PUCHAR_EUROPY: 'Puchar Europy',
    T_MATTER_FORM_RUN_CHAMPIONSHIP_MISTRZOSTWA_EUROPY: 'Mistrzostwa Europy',
    T_MATTER_FORM_RUN_CHAMPIONSHIP_PUCHAR_SWIATA: 'Puchar Świata',
    T_MATTER_FORM_RUN_CHAMPIONSHIP_MISTRZOSTWA_SWIATA: 'Mistrzostwa Świata',
    T_MATTER_FORM_RUN_CHAMPIONSHIP_INNE: 'Inne...',
    T_MATTER_FORM_RUN_LOCATION_TPN: 'na terenie TPN, wskazać miejsce',
    T_MATTER_FORM_RUN_LOCATION_OTHER: 'poza TPN',
    T_FORM_MATTER_RUN_EVENT_FORMAT: 'Format zawodów',
    T_FORM_MATTER_RUN_EVENT_SEASONS:
      'Liczba sezonów/lata na które jest składany wniosek wraz z uzasadnieniem i podaniem daty planowanej imprezy w każdym roku',
    T_FORM_MATTER_RUN_EVENT_START_DATE:
      'Data organizacji Imprezy w kolejnym roku',
    T_FORM_MATTER_RUN_EVENT_START_DATE_RES:
      'Rezerwowa data organizacji Imprezy w kolejnym roku',
    T_MATTER_CAR_TYPE_BUS_ADULTS: 'Samochód osobowy lub bus do 9 osób',
    T_MATTER_CAR_TYPE_CAR: 'Samochód osobowy do 5 osób',
    T_GENERAL_API_ERROR: 'Błąd API',
    T_LOGIN_STATUS_SUCCESS: 'Sukces',
    T_LOGIN_STATUS_FAILURE: 'Błąd',
    Trail: 'Trasy',
    T_SEO_REDIRECT: 'Przekierowania seo',
    T_SEO_REDIRECT_NEW_BUTTON: 'Dodaj przekierowanie',
    T_SEO_REDIRECT_OLD_PATH: 'Stara ścieżka',
    T_SEO_REDIRECT_NEW_PATH: 'Nowa ścieżka',
    T_SEO_REDIRECT_TYPE: 'Typ',

    T_MODULE_CUSTOMER_DEL: 'Klienci - usunięci',
    'Shop product': 'Sklep - produkt',
    'Shop shipping types': 'Sklep - metody dostawy',
    'Shop config': 'Sklep - konfiguracja',
    'Administration groups': 'Grupy administracyjne',
    'Shop shipping area cost': 'Sklep - koszty dostawy',
    'Shop variant option': 'Sklep - opcje wariantów',
    'Shop category': 'Sklep - kategorie',
    'Shop product variant': 'Sklep - warianty produktów',
    'Shop subscription': 'Sklep - prenumerata',
    'Shop order': 'Sklep - zamówienia',
    'Shop order variants': 'Sklep - produkt w zamówieniu',
    'Seo redirects': 'Przekierowania seo',
    'File manager': 'Biblioteka plików',
    'Event blocks': 'Wydarzenie - blok',
    Event: 'Wydarzenie',
    'Event category': 'Kategoria wydarzenia',
    'Bip pages': 'BIP - struktura stron',
    'Trail parameters': 'Trasy - parametry',
    'News blocks': 'Aktualność - blok',
    'Weather location': 'Punkt pogodowy',
    'Package files item': 'Pakiet plików - plik',
    'Config airly': 'Konfiguracja - airly',
    'Page block list item thumbs': 'Strona - blok listy kafelków',
    'Manager media': 'Biblioteka plików',
    'Gallery item': 'Galeria - zdjęcie',
    'Tourism notice article': 'Komunikat turystyczny',
    'Tourism information notice': 'Komunikat turystyczny - komunikat',
    'TourismNoticeArticle blocks': 'Komunikat turystyczny - blok',
    'Matter form permission': 'Załatw sprawę - zezwolenie',
    'Matter form request': 'Załatw sprawę - wniosek',
    'Bip page blocks': 'BIP - struktura stron - blok',
    'Crew items': 'Pracownicy',
    'Volunteering block list item': 'Wolontariat - blok listy',
    'Tourism notice article block list item':
      'Komunikat turystyczny - blok listy',
    'News block list item': 'Aktualność - blok listy',
    'Contest block list item': 'Konkurs - blok listy',
    'Event block list item': 'Wydarzenie - blok listy',
    'Matter block list item': 'Załatw sprawę - blok listy',
    'Page block list item': 'Struktura stron - blok listy',
    'Trail block list item': 'Trasy - blok listy',
    'Contest blocks': 'Konkurs - blok',
    'Shop promotions': 'Sklep - promocje',
    'Page thumbs': 'Struktura strona - thumby',
    Matter: 'Załatw sprawę',
    'Volunteering users': 'Wolontariusze',
    'Volunteering blocks': 'Wolontariat - blok',
    Accommodation: 'Noclegi',
    'Accommodation users': 'Noclegi - użytkownicy',
    'Bip entity': '',
    'Shop product images': 'Produkt - obrazek',
    'Shop variant': 'Sklep - wariant',
    'Home banners': 'Bannery',
    'Home banners thumbs': 'Bannery - thumby',
    Formsets: 'Formularze',
    'Formset items': 'Formularz - pole',
    'Formset item options': 'Formularz - opcje pola',
    'Main menus': 'Menu główne',
    'Trail subcategory': 'Trasy - podkategoria',
    'Bip menu category': 'BIP - kategoria menu',
    'Bip page article blocks': 'BIP - blok artykułu',
    News: 'Aktualności',

    T_FORM_MATTER_CAR_TYPE_ERROR:
      'Samochód osobowy lub bus do 9 osób możliwość wjazdu po 17-ej i po 18-ej.',
    T_FORM_MATTER_CAR_HOUR_ERROR:
      'Godzina nie jest dostępna. W weekendy wjazd wyłącznie po 18-ej. Wybierz inną datę, godzinę lub typ pojazdu.',
    T_FORM_MATTER_CAR_DATEFROM_ERROR: 'Wjazd tylko od 4 maja do 30 paźdiernika',
    T_FORM_MATTER_CAR_HOUR_RESERVED_ERROR:
      'Ta data i godzina jest już zarezerwowana, wybierz inną godzinę lub datę.',
    T_SHOP_RETURN_STATUS_NEW: 'Nowe',
    T_SHOP_RETURN_STATUS_AWAITING_FOR_PRODUCTS: 'Oczekiwanie na produkty',
    T_SHOP_RETURN_STATUS_ONGOING: 'Trwa',
    T_SHOP_RETURN_STATUS_FINISHED: 'Zakończone',
    T_SHOP_RETURN_REASON_DEFECTIVE: 'Wadliwy towar',
    T_SHOP_RETURN_REASON_NOT_AS_EXPECTED: 'Towar niezgodny z oczekiwaniem',
    T_SHOP_RETURN_REASON_OTHER: 'Inny powód',
    T_SHOP_RETURN_REASON_MECHANICAL_DAMAGE: 'Towar uszkodzony mechanicznie',
    T_SHOP_RETURN_REASON_NOT_IN_LINE_WITH_ORDER:
      'Towar niezgodny z zamówieniem',
    T_SHOP_REPORT_GROUP_BY_WEEK: 'Tygodnie',
    T_SHOP_REPORT_GROUP_BY_DAY: 'Dni',
    T_SHOP_REPORT_GROUP_BY_MONTH: 'Miesiące',
    T_SHOP_REPORT_GROUP_BY_YEAR: 'Lata',
    T_THUMB_API_MEDIAMOBILE_HOME_MOBILE: 'Mobile',
    T_THUMB_API_MEDIAMOBILE_HOME_TABLET: 'Tablet',
    T_PAGE_CHILDREN_BLOCK_TYPE_GRID: 'Kafelki',
    T_PAGE_CHILDREN_BLOCK_TYPE_LIST: 'Lista',
    T_IMAGE_FLOAT_TYPE_LEFT: 'Lewo',
    T_IMAGE_FLOAT_TYPE_RIGHT: 'Prawo',
    T_TRAIL_PARAMETER_TYPE_ENUM_BEGINNING: 'Początek',
    T_TRAIL_PARAMETER_TYPE_ENUM_END: 'Koniec',
    T_TRAIL_PARAMETER_TYPE_ENUM_LENGTH: 'Długość odcinka',
    T_TRAIL_PARAMETER_TYPE_ENUM_TIME_UP: 'Czas w góre',
    T_TRAIL_PARAMETER_TYPE_ENUM_TIME_DOWN: 'Czas w dół',
    T_TRAIL_PARAMETER_TYPE_ENUM_TILT: 'Średnie nachylenie',
    T_TRAIL_PARAMETER_TYPE_ENUM_SURFACE: 'Nawierzchnia',
    T_TRAIL_PARAMETER_TYPE_ENUM_GENERAL_TIME: 'Ogólny czas',
  },
}
