import {
  text_trail,
  // textWithPhoto,
  textWithVideo,
  //textWithLink,
  gallery,
  files,
  //faq,
  //logotypes,
  // anchors,
  // alert,
  // banner1,
  banner2,
  links_trail,
  // list,
  // newsletter,
  // product,
  // trails,
  // column,
  // crew,
  // miginfo,
  // formsetBlock,
  // tourismInformationNotices,
  // trailConditions,
  // embed,
  // stops
} from '_schema/_blocks'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockAnchorEndpoint,
  blockFileEndpoint,
  blockLinkEndpoint,
  blockLinkThumbEndpoint,
  blockListItemEndpoint,
  blockListItemThumbEndpoint,
  definitionRefblockLinkEndpoint = '#TrailBlockLink'
) => ({
  types: [
    text_trail(),
    // textWithPhoto(photoEndpoint, blockThumbEndpoint),
    textWithVideo(photoEndpoint, blockThumbEndpoint),
    //textWithLink(),
    gallery(),
    files(),
    //faq(),
    //logotypes(),
    // anchors(blockAnchorEndpoint),
    // alert(),
    // banner1(photoEndpoint, blockThumbEndpoint),
    links_trail(photoEndpoint, blockLinkEndpoint, blockLinkThumbEndpoint),
    banner2(photoEndpoint, blockThumbEndpoint),
    // list(photoEndpoint, blockListItemEndpoint, blockListItemThumbEndpoint),
    // column(blockListItemEndpoint),
    // newsletter(blockListItemEndpoint),
    // product(photoEndpoint, blockThumbEndpoint),
    // trails(photoEndpoint, blockThumbEndpoint, blockFileEndpoint),
    // crew(),
    // formsetBlock(),
    // miginfo(),
    // tourismInformationNotices(),
    // trailConditions(),
    // stops(blockLinkEndpoint, definitionRefblockLinkEndpoint),
    // embed(),
  ],
})

export default schema
