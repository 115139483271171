const schema = {
  endpoint: '/api/shop_discount_codes',
  resource: {
    definition: '#ShopDiscountCode',
    properties: {
      code: {
        description: 'T_MODULE_SHOP_DISCOUNT_CODE_CODE',
        validate: ['required'],
      },
      rate: {
        description: 'T_MODULE_SHOP_DISCOUNT_CODE_RATE',
        validate: ['required'],
      },
      floatPrice: {
        type: 'number',
        description: 'T_MODULE_SHOP_DISCOUNT_CODE_MINIMAL_PRICE',
        validate: ['required', 'money'],
      },
      startDate: {
        type: 'date',
        description: 'T_MODULE_SHOP_DISCOUNT_CODE_START_DATE',
        validate: ['required'],
      },
      endDate: {
        type: 'date',
        description: 'T_MODULE_SHOP_DISCOUNT_CODE_END_DATE',
        validate: ['required'],
      },
      globalLimit: {
        validate: ['required'],
      },
      limitPerUser: {
        description: 'Limit użyć na klienta',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
