import React, { useMemo } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { translate } from '_helpers/translate'
import schema from '../../../_schema/volunteerUser'
import routes from '../routes'
import itemSchema from '../../../_schema/volunteerUserContract'
import itemRoutes from '../routes'
import { EditHeaderWithCustomTitle } from '../../../components/EditHeaderWithCustomTitle'
import { operations } from '../../../components/table/_columns/operations'
import { DateCell } from '../../../components/table/cells/DateCell'

export const ContractCollection = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: translate('Data podpisania umowy'),
        accessor: `startDate`,
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: DateCell,
      },
      {
        header: translate('Data zakończenia umowy'),
        accessor: `endDate`,
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: DateCell,
      },
      {
        header: translate('Identyfikator umowy'),
        accessor: `contractIdcard`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        ...operations(`fullName`, true, true, false),
        width: '10%',
      },
    ],
    []
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_VOLUNTEER_USER_CONTRACT_NEW_BUTTON')}
        buttonPath={`${itemRoutes().contractNew.path}?pid=${match.params.id}`}
      >
        <EditHeaderWithCustomTitle
          resource={resource}
          editPath={routes().edit.path}
          textBeforeTitle={`Umowy wolontariusza: ${resource.fullName}`}
        />
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${itemSchema.endpoint}.get`}
        endpoint={itemSchema.endpoint}
        definition={itemSchema.resource.definition}
        storeCollectionId={`${itemSchema.endpoint}?iri[parent]=${parentIri}`}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            type: 'string',
            value: false,
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
        ]}
        columns={itemColumns}
        customResourceSchema={itemSchema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        schemaParameters={[
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={true}
        isRowLinkable={true}
        editPath={itemRoutes().contractEdit.path}
        autoWidth={false}
        paginable={false}
      />
    </Paper>
  )
}
