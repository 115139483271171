import fileSchema from './media'
import photoSchema from './photo'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/package_files',
  access: 'packageFile',
  resource: {
    definition: '#PackageFile',
    properties: {
      title: {
        validate: ['required'],
      },
    },
  },
  subresources: {
    item: {
      endpoint: '/api/package_file_items',
      definition: '#PackageFileItem',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            alt: {
              description: 'T_GENERAL_ALT',
            },
          },
        },
        media: {
          type: 'file',
          description: 'T_GENERAL_FILE',
          endpoint: fileSchema.endpoint,
          validate: ['maxSize'],
        },
        image: {
          type: 'image',
          description: 'T_GENERAL_PHOTO',
          endpoint: photoSchema.endpoint.single,
          validate: ['maxSize'],
          thumbs: {
            endpoint: '/api/package_file_item_thumbs',
          },
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
        nummberDownloads: {
          description: 'Ilość pobrań',
          label: 'Ilość pobrań',
          type: 'number',
        },
      },
    },
  },
}

export default schema
