export const BLOCK_TYPES = {
  '/api/bip_block_types/TEXT': 'text',
  '/api/bip_block_types/TEXT_WITH_PHOTO': 'text_with_photo',
  '/api/bip_block_types/FILES': 'files',
  '/api/bip_block_types/ENTITIES_MAP': 'entities_map',
  '/api/bip_block_types/ARTICLES_LIST': 'articles_list',
}

export const BLOCK_TYPES_IRI = {
  TEXT: '/api/bip_block_types/TEXT',
  TEXT_WITH_PHOTO: '/api/bip_block_types/TEXT_WITH_PHOTO',
  FILES: '/api/bip_block_types/FILES',
  ENTITIES_MAP: '/api/bip_block_types/ENTITIES_MAP',
  ARTICLES_LIST: '/api/bip_block_types/ARTICLES_LIST',
}
