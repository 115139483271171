import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { AddCircleOutline } from '@material-ui/icons'
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { useSelector } from 'react-redux'
import { SchemaError } from '../../../_helpers/schemaError'
import { translate } from '../../../_helpers/translate'
import { Form } from '../../../components/form'
import addShipmentSchema from '../../../_schema/shopElektronicznyNadawcaAddShipment'
import { constants } from '../../../components/table/_state'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles({
  content: {
    textAlign: 'left',
  },
})

export const ElektronicznyNadawcaLabelModal = ({
  title = translate('Generuj etykietę'),
  minWidth = '150px',
  classes = {},
  defaultData = {},
  tableState,
  tableStateDispatch,
  resource,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [formState, setFormState] = useState(null)

  const currentValues = formState?.values

  const handleDialogToggle = useCallback(() => {
    setIsDialogOpen(state => !state)
  }, [])

  const [buttonRef, setButtonRef] = useState(null)

  const onButtonRef = element => {
    setButtonRef(element)
  }

  //PRZESYLKA_POLECONA_KRAJOWA
  //PACZKA_POCZTOWA
  //POCZTEX20
  //POCZTEX_PICKUP_POINT
  //PACZKA_ZAGRANICZNA

  const cashOnDeliveryMethods = ['POCZTEX20']
  const foreignMethods = ['PACZKA_ZAGRANICZNA']
  const pickupPoint = ['POCZTEX_PICKUP_POINT']
  const normal = ['PRZESYLKA_POLECONA_KRAJOWA', 'PACZKA_POCZTOWA', 'POCZTEX20']

  let availableMethods = []
  if (
    resource?.cashOnDelivery &&
    resource?.shippingOperator?.name !== 'POCZTA_POLSKA_PICKUP_POINT'
  ) {
    availableMethods = [...availableMethods, ...cashOnDeliveryMethods]
  } else if (resource?.countryTitle && resource?.countryTitle !== 'Polska') {
    availableMethods = [...availableMethods, ...foreignMethods]
  } else if (
    resource?.shippingOperator?.name === 'POCZTA_POLSKA_PICKUP_POINT'
  ) {
    availableMethods = [...availableMethods, ...pickupPoint]
  } else if (resource?.countryTitle) {
    availableMethods = [...availableMethods, ...normal]
  }

  if (!resource?.countryTitle) {
    availableMethods = []
  }

  let typeEndpoint = addShipmentSchema.resource.properties.type.endpoint

  for (let i = 0; i < availableMethods.length; i++) {
    const endpointFilter = '&name[]=' + availableMethods[i]

    if (!typeEndpoint.includes(endpointFilter)) {
      typeEndpoint = typeEndpoint + endpointFilter
    }
  }

  addShipmentSchema.resource.properties.type.allowedNames = availableMethods

  const getProperties = () => {
    if (typeof currentValues?.type === 'string') {
      const enumName = currentValues.type.split('/').pop()
      if (addShipmentSchema.hasOwnProperty(enumName)) {
        const newProperties = {
          ...addShipmentSchema.resource.properties,
          ...addShipmentSchema[enumName],
        }

        if (currentValues?.pocztexContent !== 'INNA') {
          delete newProperties.pocztexContentOther
        }

        if (
          newProperties.bufor.endpoint &&
          !newProperties.bufor.endpoint.includes('sendDate[after]')
        ) {
          newProperties.bufor.endpoint = `${
            newProperties.bufor.endpoint
          }&sendDate[after]=${new Date().toISOString().split('T')[0]}`
        }

        return newProperties
      } else {
        return addShipmentSchema.resource.properties
      }
    } else {
      return addShipmentSchema.resource.properties
    }
  }

  const defaultClasses = useStyles()

  const schemaDefinitions = useSelector(state => state.schema)

  if (!schemaDefinitions) {
    throw new SchemaError()
  }

  const handleSuccess = resource => {
    tableStateDispatch({ type: constants.RELOAD })
    handleDialogToggle()
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddCircleOutline />}
        onClick={handleDialogToggle}
        style={{ minWidth: minWidth }}
      >
        {title}
      </Button>
      <Dialog maxWidth="md" onClose={handleDialogToggle} open={isDialogOpen}>
        <DialogTitleWithClose onClose={handleDialogToggle}>
          {title} - Poczta polska
        </DialogTitleWithClose>
        <DialogContent
          className={clsx(defaultClasses.content, classes.content)}
        >
          {!resource?.countryTitle && (
            <Alert severity="error">
              Zamówienie nie ma ustawionego kraju dostawy!
            </Alert>
          )}
          {resource?.cashOnDelivery && (
            <div>
              <b>Płatność za pobraniem</b>
            </div>
          )}
          {resource?.shippingOperator?.name ===
            'POCZTA_POLSKA_PICKUP_POINT' && (
            <div>
              <div>
                <b>Odbiór w punkcie (poczta polska)</b>
              </div>
              <div>
                Punkt odbioru: {resource?.pickupPointName} (
                {resource?.pickupPoint})
              </div>
            </div>
          )}
          <div>Kraj docelowy: {resource.countryTitle}</div>
          <Form
            properties={getProperties()}
            method="POST"
            url={addShipmentSchema.endpoint}
            showSubmitAndStayButton={false}
            showCancelButton={false}
            buttonContainerRef={buttonRef}
            handleSuccess={handleSuccess}
            width={600}
            previousState={formState}
            setCurrentState={setFormState}
            defaultData={defaultData}
          />
        </DialogContent>
        <DialogActions ref={onButtonRef}></DialogActions>
      </Dialog>
    </>
  )
}

ElektronicznyNadawcaLabelModal.propTypes = {
  title: PropTypes.string,
  minWidth: PropTypes.string,
  classes: PropTypes.shape({
    content: PropTypes.string,
  }),
  defaultData: PropTypes.object,
  tableState: PropTypes.shape({
    data: PropTypes.shape({
      items: PropTypes.array.isRequired,
    }).isRequired,
    config: PropTypes.shape({
      page: PropTypes.number,
    }).isRequired,
  }).isRequired,
  tableStateDispatch: PropTypes.func.isRequired,
  resource: PropTypes.object,
}
