import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const textWithVideo = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.TEXT_WITH_VIDEO,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_VIDEO',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        sectionTitle: {
          type: 'string',
          description: 'T_GENERAL_SECTION_TITLE',
        },
        title: {
          description: 'T_GENERAL_MOVIE_TITLE',
        },
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
        alt: {
          description: 'T_GENERAL_ALT',
          type: 'string',
        },
      },
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_VIDEO_MASK',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            alt: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
                 [LANGS_IRI.EN]: {
                   alt: media.translations[LANGS_IRI.EN].alt,
                 }
                  */
        })
      },
    },
    file: {
      type: 'file',
      description: 'T_GENERAL_VIDEO',
      endpoint: photoEndpoint,
      validate: ['maxSize'],
    },
    ytCode: {
      type: 'string',
      description: 'T_GENERAL_YOUTUBE_CODE',
      hint: 'T_GENERAL_YOUTUBE_CODE_INFO',
    },
  },
})
