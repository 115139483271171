import _block from '../pages/Pages/_blocks'
import mediaSchema from './media'
import photoSchema from './photo'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/pages',
  clone_endpoint: '/api/pages/clone',
  access: 'page',
  resource: {
    definition: '#Page',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
            formWidth: '100%',
          },
          alt: {
            label: 'Znacznik ALT zdjęcia',
            type: 'textarea',
          },
          link: {
            description: 'T_GENERAL_EXTERNAL_LINK',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {
            type: 'slug',
            routes: {
              [LANGS_IRI.PL]: '/:slug',
              [LANGS_IRI.EN]: '/en/:slug',
            },
          },
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/page_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      seoMedia: {
        type: 'image',
        description: 'T_GENERAL_SEO_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: false,
      },
      showChildrenBlock: {
        type: 'boolean',
        description: 'Pokaż blok z podstronami',
      },
      childrenBlockType: {
        label: 'Rodzaj bloku',
        type: 'resource',
        endpoint: '/api/children_block_types',
        titleAccessor: 'trans',
        transTitle: true,
        validate: ['required'],
      },
      hideInChildrenBlock: {
        type: 'boolean',
        description: 'Ukryj w bloku z podstronami',
      },
      // menuMain: {
      //   description: 'T_MENU_TOP_LEVEL',
      // },
      // menuChild: {
      //   description: 'T_MENU_CHILD',
      // },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/page_blocks',
      definition: '#PageBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/page_block_thumbs',
        '/api/page_block_anchors',
        '/api/page_block_files',
        '/api/page_block_links',
        '/api/page_block_link_thumbs',
        '/api/page_block_list_items',
        '/api/page_block_list_item_thumbs',
        '/api/page_block_pricelist_items',
        '/api/page_block_banners',
        '/api/page_block_banner_thumbs',
        '#PageBlockListItem',
        '#PageBlockLink'
      ).types,
    },
  },
}

export default schema
