import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import schema from '_schema/shopReturn'
import routes from './routes'
import { DatetimeCell } from '../../components/table/cells/DatetimeCell'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: 'Numer zwrotu',
        accessor: `returnId`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        header: 'Numer zamówienia',
        accessor: `orderId`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        header: 'Klient',
        accessor: `fullName`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        header: 'Status',
        accessor: `status.trans`,
        translateAccessor: true,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        header: 'Data utworzenia',
        accessor: `createdAt`,
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: DatetimeCell,
      },
      {
        ...operations(`fullName`, true, true, false, true, false),
        width: '5%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj zwrot')}
        buttonPath={routes().new.path}
      >
        {translate('Lista zwrotów')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        orderable={false}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        numerable={false}
      />
    </Paper>
  )
}
