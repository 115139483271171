import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { Alert } from '@material-ui/lab'

const TEXTAREA_ROWS = 5
const TEXTAREA_MAX_ROWS = 8

const useStyles = makeStyles(theme => ({
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const StringType = ({
  name,
  type = 'string',
  label,
  hint = null,
  initialValue = null,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setAdditional = null,
  setError,
  syncWithAdditional = false,
  fullWidth = false,
  warningAlertWhenNullOrEmpty = '',
  errorAlertWhenNullOrEmpty = '',
  length = null,
  hideWhenEmpty = false,
  asString = false,
}) => {
  const handleChange = e => {
    const value =
      type === 'integer'
        ? parseInt(e.target.value)
        : type === 'number'
        ? parseFloat(e.target.value)
        : e.target.value === ''
        ? null
        : e.target.value
    setValue(name, asString ? value?.toString() : value)
    validateField(value)

    syncWithAdditional && setAdditional && setAdditional({ [name]: value })
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(
    () => {
      if (initialValue !== null) {
        validateField(initialValue)
      }

      syncWithAdditional &&
        setAdditional &&
        setAdditional({ [name]: initialValue })

      if (initialValue !== null && !value) {
        setValue(name, initialValue)
      }
    },
    // eslint-disable-next-line
    [
      validateField,
      initialValue,
      syncWithAdditional,
      setAdditional,
      name,
      // value, //nie dawać !!bo sie zapętla
      setValue,
    ]
  )

  let additional =
    type === 'integer'
      ? { inputProps: { step: 1 } }
      : type === 'textarea'
      ? {
          minRows: TEXTAREA_ROWS,
          maxRows: TEXTAREA_MAX_ROWS,
          multiline: true,
          FormHelperTextProps: { style: { marginLeft: 0 } },
        }
      : {}

  if (length) {
    additional = {
      ...additional,
      inputProps: {
        ...additional.inputProps,
        maxLength: length,
      },
    }
  }

  const isVisible = () => {
    if (!hideWhenEmpty) {
      return true
    }

    return !!value
  }

  const classes = useStyles()
  return (
    isVisible() && (
      <>
        {warningAlertWhenNullOrEmpty && (!value || value === '') && (
          <Alert variant="filled" severity="warning">
            {translate(warningAlertWhenNullOrEmpty)}
          </Alert>
        )}
        {errorAlertWhenNullOrEmpty && (!value || value === '') && (
          <Alert variant="filled" severity="error">
            {translate(errorAlertWhenNullOrEmpty)}
          </Alert>
        )}
        <TextField
          name={name}
          type={
            type === 'integer'
              ? 'number'
              : ['string', 'textarea'].includes(type)
              ? 'text'
              : type
          }
          label={
            translate(label?.text || label) +
            (validators && validators.includes('required') ? ' *' : '')
          }
          value={value || value === 0 ? value : ''}
          disabled={disabled}
          onChange={handleChange}
          error={renderError && !!error}
          helperText={translate(renderError && error ? error : hint)}
          InputLabelProps={
            label?.color && {
              style: {
                color: label.color,
              },
            }
          }
          fullWidth={fullWidth}
          variant="standard"
          {...additional}
        />
        {compare && (
          <div
            className={clsx(
              classes.compare,
              value !== compareValue && classes.compareNeq
            )}
          >
            {compareValue}
          </div>
        )}
      </>
    )
  )
}

StringType.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compareValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setAdditional: PropTypes.func,
  setError: PropTypes.func.isRequired,
  syncWithAdditional: PropTypes.bool,
  fullWidth: PropTypes.bool,
  warningAlertWhenNullOrEmpty: PropTypes.string,
  errorAlertWhenNullOrEmpty: PropTypes.string,
  hideWhenEmpty: PropTypes.bool,
  asString: PropTypes.bool,
}
