import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
// import { operations } from 'components/table/_columns/operations'
import { operations } from './table/columns/operations'
// import queryString from 'query-string'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/mainMenu'
import routes from './routes'

import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import CallMissedIcon from '@material-ui/icons/CallMissed'
import { CustomButton } from 'components/CustomButton'
import { Tooltip } from '@material-ui/core'

export const Collection = ({
  wrapWithPaper = true,
  showNewButton = true,
  ...rest
}) => {
  const profile = useSelector(state => state.profile)

  //const { pid } = queryString.parse(rest.location.search)
  const pid = rest.match.params.id

  const columns = useMemo(
    () => [
      {
        header: translate('Title'),
        accessor: `pageTitle`,
        // filterName: 'translations.title',
        // filterable: true,
        // Cell: MainCell,
        width: '73%',
      },

      {
        ...operations(
          `pageTitle`,
          true,
          true,
          profile.isModerator ? true : false,
          true,
          true
        ),
        width: '15%',
      },
    ],
    [profile.isModerator]
  )

  const defaultFilters = pid
    ? {
        'exists[parent]': true,
        pagination: true,
        'parent.uuid': pid,
      }
    : {
        'exists[parent]': false,
        pagination: true,
      }

  const customFilters = [
    // {name: 'pageType', title: 'page type', value: 'project'},
  ]

  const WrapComponent = wrapWithPaper ? Paper : 'div'

  const collectionId = 'main_menu'

  const [state, setState] = React.useState({
    isFetching: true,
    fetchError: false,
    resource: null,
  })

  React.useEffect(
    () => {
      if (!pid) return

      const controller = new AbortController()
      const { signal } = controller

      setState({
        ...state,
        isFetching: true,
      })

      fetchDataHandleAuthError(
        `${schema.endpoint}/${pid}`,
        'GET',
        { signal },
        response => {
          setState({
            isFetching: false,
            resource: response,
          })
        },
        error => {
          if (error.response.title === 'AbortError') {
            return
          }
          setState({
            isFetching: false,
            fetchError: true,
          })
        }
      )

      return () => controller.abort()
    },
    // eslint-disable-next-line
    [pid]
  )

  const { isFetching, resource } = state

  const additionalButtons = React.useMemo(() => {
    return (
      resource && (
        <CustomButton
          disabled={isFetching}
          path={
            resource.parentUuid
              ? routes().indexParent.path.replace(
                  ':id',
                  `${resource.parentUuid}`
                )
              : routes().index.path
          }
        >
          <Tooltip title={translate('Powrót do menu wyżej')}>
            <CallMissedIcon />
          </Tooltip>
        </CustomButton>
      )
    )
  }, [isFetching, resource])

  return (
    <WrapComponent key={pid}>
      <CollectionHeader
        buttonTitle={translate('Dodaj nową pozycję')}
        buttonPath={
          pid ? routes().newParent.path.replace(':pid', pid) : routes().new.path
        }
        hideButton={!showNewButton}
        additionalButtons={additionalButtons}
      >
        {translate('Lista menu')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpointProvider}.get`}
        endpoint={schema.endpointProvider}
        columns={columns}
        definition={schema.resource.definition}
        customResourceSchema={schema.resource}
        defaultFilters={defaultFilters}
        defaultSorters={{ ord: 'desc' }}
        selectable={[publish.on(), publish.off()]}
        isRowSelectable={resource => profile?.openform || resource.isStatable}
        editPath={routes().edit.path}
        autoWidth={false}
        customFilters={customFilters}
        orderable={true}
        isRowLinkable={true}
        storeCollectionId={`${collectionId}-${pid}`}
        parameters={[
          {
            name: 'exists[parent]',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'parent.uuid',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </WrapComponent>
  )
}

Collection.propTypes = {
  wrapWithPaper: PropTypes.bool,
  showNewButton: PropTypes.bool,
}
